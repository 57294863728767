@font-face {
  font-family: "PromptRegular";
  src: url("../fonts/UbuntuRegular/UbuntuRegular.eot");
  src: url("../fonts/UbuntuRegular/UbuntuRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/UbuntuRegular/UbuntuRegular.woff") format("woff"),
    url("../fonts/UbuntuRegular/UbuntuRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuLight";
  src: url("../fonts/UbuntuLight/UbuntuLight.eot");
  src: url("../fonts/UbuntuLight/UbuntuLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/UbuntuLight/UbuntuLight.woff") format("woff"),
    url("../fonts/UbuntuLight/UbuntuLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuMedium";
  src: url("../fonts/UbuntuMedium/UbuntuMedium.eot");
  src: url("../fonts/UbuntuMedium/UbuntuMedium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/UbuntuMedium/UbuntuMedium.woff") format("woff"),
    url("../fonts/UbuntuMedium/UbuntuMedium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuBold";
  src: url("../fonts/UbuntuBold/UbuntuBold.eot");
  src: url("../fonts/UbuntuBold/UbuntuBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/UbuntuBold/UbuntuBold.woff") format("woff"),
    url("../fonts/UbuntuBold/UbuntuBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PromptRegular";
  src: url("../fonts/Prompt-Regular/Prompt-Regular.eot");
  src: url("../fonts/Prompt-Regular/Prompt-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Prompt-Regular/Prompt-Regular.woff") format("woff"),
    url("../fonts/Prompt-Regular/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PromptMedium";
  src: url("../fonts/Prompt-Medium/Prompt-Medium.eot");
  src: url("../fonts/Prompt-Medium/Prompt-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Prompt-Medium/Prompt-Medium.woff") format("woff"),
    url("../fonts/Prompt-Medium/Prompt-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PromptBold";
  src: url("../fonts/Prompt-Bold/Prompt-Bold.eot");
  src: url("../fonts/Prompt-Bold/Prompt-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Prompt-Bold/Prompt-Bold.woff") format("woff"),
    url("../fonts/Prompt-Bold/Prompt-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
#root {
  overflow-x: hidden;
}
.btn.video span:before,
.toggle_menu span:after,
.toggle_menu span:before,
.section_header_line:after,
.topline_wrap .topline:after,
.video_section .video_title li:before,
.video_section .video_title p:after,
.sales .progress:after,
.distribution .distribution_info > div:after,
.distribution .distribution_info > div:before,
.roadmap .roadmap_item_title:before,
.roadmap li:before,
.roadmap .roadmap_item_title:after,
.team.owl-carousel .socials:before,
#member-info-popup .socials:before {
  content: "";
  position: absolute;
}

.toggle_menu span,
.toggle_menu span:after,
.toggle_menu span:before {
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500 !important;
  color: #333;
}
.btn {
  padding: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 4px !important;
}

.subscribe_now .content > div,
.coin .ticket,
.bonuses .ticket,
.subscribe_form > div {
  position: relative;
  padding: 40px;
}
.subscribe_now .content > div:before,
.coin .ticket:before,
.bonuses .ticket:before,
.subscribe_form > div:before {
  content: "";
  position: absolute;
  top: 22px;
  bottom: 22px;
  left: 22px;
  right: 22px;
  margin: auto;
  border: 1px solid rgba(29, 19, 80, 0.2);
  width: -webkit-calc(100% - 44px);
  width: calc(100% - 44px);
  height: -webkit-calc(100% - 44px);
  height: calc(100% - 44px);
  z-index: 0;
}

.btn {
  border-radius: 0;
  padding: 10px 20px;
  text-transform: uppercase;
  font: 14px/1 "PromptRegular";
  color: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #6c1a74;
  display: inline-block;
}
.btn:hover {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #fff;
  background-color: #8e209e;
}
.btn.dark {
  background-color: #1d1350;
}
.btn.dark:hover {
  background-color: #251c7a;
}
.btn.video span {
  position: relative;
  padding-right: 28px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.btn.video span:before {
  background: url("../img/icons/play.svg") no-repeat center;
  background-size: 100%;
  width: 16px;
  height: 16px;
  top: -webkit-calc(50% - 8px);
  top: calc(50% - 8px);
  right: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.content {
  max-width: 1140px;
  padding: 32px 16px;
  margin: 0px auto;
  display: block;
}

/* input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important; } */

form {
  -moz-placeholder-font: 18px/1 "PromptRegular";
  -moz-placeholder-color: #fff;
  -ms-input-placeholder-font: 18px/1 "PromptRegular";
  -ms-input-placeholder-color: #fff;
}
form ::-webkit-input-placeholder {
  font: 18px/1 "PromptRegular";
  color: #fff;
}
form ::-moz-placeholder {
  font: 18px/1 "PromptRegular";
  color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

.owl-nav .owl-next,
.owl-nav .owl-prev {
  content: "";
  position: absolute;
  top: -webkit-calc(50% - 9px);
  top: calc(50% - 9px);
  width: 10px;
  height: 18px;
  margin: 8px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  background-image: url("../img/icons/arrow_carousel.png");
}
.owl-nav .owl-next:hover,
.owl-nav .owl-prev:hover {
  background-position-x: -10px;
}

.owl-nav .owl-next {
  left: 100%;
}

.owl-nav .owl-prev {
  right: 100%;
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1);
}

.toggle_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: center;
  width: 30px;
  height: 53px;
  min-height: 100%;
  cursor: pointer;
  display: none;
  background-color: #1d1350;
  color: white;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
  padding: 0 0 0 10px;
}
.toggle_menu.on span {
  background-color: transparent;
}
.toggle_menu.on span:after {
  -webkit-transform: rotate(45deg) translate(5px, 1px);
  -ms-transform: rotate(45deg) translate(5px, 1px);
  transform: rotate(45deg) translate(5px, 1px);
}
.toggle_menu.on span:before {
  -webkit-transform: rotate(-45deg) translate(7px, -4px);
  -ms-transform: rotate(-45deg) translate(7px, -4px);
  transform: rotate(-45deg) translate(7px, -4px);
}
.toggle_menu span {
  position: relative;
  display: block;
}
.toggle_menu span:after {
  left: 0px;
  top: -6px;
}
.toggle_menu span:before {
  left: 0px;
  top: 6px;
}

p {
  font-size: 14px;
}

body {
  margin: 0 auto;
  min-width: 320px;
  font-family: "PromptRegular" !important;
}

.section_header {
  color: #041b2d;
  font: 40px/1.2 "PromptRegular";
  margin-bottom: 60px;
}

.section_header_line {
  margin-top: 60px;
  position: relative;
  padding-left: 35px;
  z-index: 2;
  color: #7f7f7f;
  margin-bottom: 18px;
  font-size: 18px;
  text-align: center;
}
/* .section_header_line:after {
  top: 0px;
  left: 0px;
  width: 6px;
  height: 100%;
  background-color: #6c1a74;
} */

section,
section.content {
  margin-bottom: 40px;
}

/* HEADER*/
.header_logo {
  margin-right: 10px;
}
.header_logo .logo {
  height: 100%;
  padding: 11px 20px;

  display: flex;
}
.header_logo img {
  display: block;
}

header .topline_wrap {
  z-index: 100;
  min-width: 320px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  margin-right: 14px;
  background: #1a092e;
}
header .topline_wrap.darkHeader {
  background: rgba(0, 0, 0, 0.6);
}
header .right_topline_buttons {
  margin-left: auto;
}
header nav {
  margin: 5px 10px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
header nav ul {
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 0;
}
header nav ul li {
  margin: 0 10px;
}
header nav a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font: 14px/1 "PromptRegular";

  display: block;
  color: #fff;
}
header nav a:hover {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #76297d;
}

.slider_main {
  height: calc(100vh - 0px);
}
.slider_main .slick-slide {
  margin: 0 0;
}
.slide_item {
  height: calc(100vh - 0px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.slide_content {
  position: absolute;
  top: 150px;

  z-index: 1;
  width: 100%;
}
.slide_content h2 {
  font-size: 32px;
  color: #1d1350;
  text-transform: uppercase;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
}
.slide_content h3 {
  color: #1d1350;
  text-align: center;
  font-size: 20px;
  margin-top: 7px;
}
.white_text.slide_content h2,
.white_text.slide_content h3 {
  color: #fff;
}
.slide3 .slide_content h2 {
  max-width: 100%;
  min-width: 836px;
}
.slide0 .slide_content h2 {
  color: #1d1350;
}
.slide0 .slide_content h3 {
  color: #1d1350;
  text-align: center;
  font-size: 20px;
  margin-top: 7px;
}
.slide_content h2 span {
  text-transform: lowercase;
}
.slide4 .slide_content h2 {
  display: inherit;
}
.slide_content h5 {
  text-align: center;
  color: #1d1350;
  font-size: 25px;
}
.slide1 .slide_content h2 {
  text-align: center;
  max-width: 60%;
}
.slide0 {
  background-image: url("../img/banner/0.jpg");
}
.slide1 {
  background-image: url("../img/banner/1.jpg");
}
.slide2 {
  background-image: url("../img/banner/2.jpg");
}
.slide3 {
  background-image: url("../img/banner/3.jpg");
}
.slide4 {
  background-image: url("../img/banner/4.jpg");
}

.popup-youtube_btn {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom_banner_sec {
  padding: 20px 0;
  background: rgba(26, 10, 46, 0.71);
  position: absolute;
  width: 100%;
  bottom: 0;
}
.ul_block {
  display: flex;
  white-space: nowrap;
  justify-content: space-evenly;
}
ul.ul_block li {
}
.main-page-service-block {
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.main-page-service-block h4 {
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 22px;
}
.main-page-service-block h2 {
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 1.1px;
}

.header_bg_1 {
  background-image: url("../img/bg/header_bg.jpg");
}

.header_bg_2 {
  background-image: url("../img/bg/header_bg2.jpg");
}

.header_bg_3 {
  background-image: url("../img/bg/header_bg3.jpg");
}

.header_bg_4 {
  background-image: url("../img/bg/header_bg4.jpg");
}

.topline_bg {
  background: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.topline_bg.topline_wrap .topline:after {
  background: #e3dbe8;
}

.topline_wrap {
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.topline_wrap .topline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background: transparent;
}

.topline_wrap .btn.account {
  height: 100%;
  padding: 5px 30px;
  background-color: #6a0074;
}
.topline_wrap .btn.transparent {
  margin-right: 20px;
  background-color: transparent;
  color: #1d1350;
  border: 2px solid #1d1350;
}
.topline_wrap .btn.transparent:hover {
  color: #6c1a74;
  border-color: #6c1a74;
}
.topline_wrap .lang {
  color: #1d1350;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: right;
  font: 14px/1 "PromptRegular";
}

.header_content {
  width: 100%;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.header_content #watch_interview {
  text-align: center;
}
.header_content h1 {
  color: #1d1350;
  font: 36px/1.2 "UbuntuMedium";
  margin-bottom: 24px;
  text-align: left;
  text-transform: uppercase;
}
.header_content h2 {
  color: rgba(0, 0, 0, 0.6);
  font: 16px/1.2 "PromptRegular";
  margin-bottom: 32px;
  color: #1d1350;
}
.header_content .btn_wrap {
  margin: 0;
  margin-left: 70px;
  margin-top: 22px;
  max-width: 365px;
}
.header_content .btn_wrap .btn {
  min-width: 329px;
  text-align: left;
  margin-bottom: 10px;
  padding: 10px;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
}
.header_content .btn_wrap .btn span {
  display: block;
  width: 100%;
}
.timer-container #head span {
  display: inline-block;
  margin-bottom: 7px;
  font-size: 16px;
  line-height: 20px;
}
.timer-container h3 {
  padding: 0 15px;
}
a.inner_btn {
  padding: 10px 20px;
  text-transform: uppercase;
  font: 13px/1 "PromptRegular";
  color: #fff;
  transition: all 0.2s;
  background-color: #6c1a74;
  display: inline-block;
  width: 130px;
}
.header_content .btn_wrap .btn img {
  height: 40px;
}
.header_content .btn_wrap .btn:before {
  content: "";
  position: absolute;
  left: -25px;
  border-bottom: 2px solid #6c1a74;

  width: 25px;
}
.btn_wrap ul {
  position: relative;
}
.btn_wrap li:before {
  content: "";
  position: absolute;
  left: -25px;
  border-left: 2px solid #6c1a74;
  height: 100%;
  width: 1px;
  top: -30px;
  /* bottom: 8px; */
}
.header_bottom {
  color: #fff;
  width: 100%;
}
.header_bottom > div {
  width: 50%;
}
.header_bottom > div:nth-child(1) {
  background-color: rgba(108, 26, 116, 0.4);
  padding: 40px 88px 40px 0;
}
.header_bottom h5 {
  font: 12px/1.2 "PromptRegular";
  margin-bottom: 10px;
}
.header_bottom .timer_wrap {
  width: -webkit-calc((1140px/2) - 88px - 15px);
  width: calc((1140px / 2) - 88px - 15px);
  float: right;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header_bottom .timer {
  width: 65%;
}
.header_bottom .price {
  width: 35%;
}
.header_bottom .price p {
  font: 22px/1.2 "PromptRegular";
  border: 1px solid #fff;
  padding: 16.5px 20px;
  border-left: none;
}
.header_bottom .price p span {
  font-weight: bold;
}

/* TIMER */
.ico_timer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  padding: 20px 40px;
}
.ico_timer .data_wrap {
  position: relative;
}
.ico_timer .data_wrap:not(:nth-child(1)) {
  margin-left: 25px;
}
.ico_timer .data_wrap .hint {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-transform: uppercase;
  line-height: 17px;
  font: 10px/1 "PromptRegular";
  text-transform: uppercase;
}
.ico_timer .data {
  font: 30px/1 "UbuntuBold";
  padding-bottom: 15px;
}

/* Partners */
.partners {
  margin: 40px 20px;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
}
.partners .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.partners .partner {
  margin: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.partners img {
  display: block;
  width: 100%;
  max-width: 160px;
  max-height: 66px;
  -o-object-fit: contain;
  object-fit: contain;
}

/* VIDEO */
.video_section .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.video_section .video {
  width: -webkit-calc(55% - 32px);
  width: calc(55% - 32px);
  position: relative;
}
.video_section .video > img {
  width: 100%;
  display: block;
}
.video_section .video .play {
  width: 46px;
  margin-bottom: 40px;
}
.video_section .video .logo_icon {
  width: 47px;
  display: block;
  margin-bottom: 20px;
}
.video_section .video .logo_text {
  width: 166px;
  display: block;
}

.video_section .video a:focus {
  color: #fff !important;
}
.video_section .video .video_wrap {
  width: -webkit-calc(100% - 78px);
  width: calc(100% - 78px);
  max-width: 820px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  right: 39px;
  padding-bottom: 48.75%;
  height: 0px;
  border: 1px solid #fff;
}
.video_section .video .video_wrap iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
}

.video_section .video_title {
  width: 45%;
  color: #1d1350;
}
.video_section .video_title li {
  padding-left: 16px;
  position: relative;
  padding-bottom: 15px;
  cursor: pointer;
}
.cryptoBanner img {
  height: 135px;
  width: 100%;
  object-fit: cover;
}
.video_section .video_title li:hover p {
  height: auto;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.video_section .video_title li:hover p:after {
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.video_section .video_title li p span {
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
}
.join_now {
  background: #f8f6fb;
  padding: 25px 0;
}
.join_now h5 {
  text-transform: uppercase;
  margin-bottom: 15px;
}
.video_section .video_title h4 {
  font: 18px/1.2 "UbuntuMedium";
  margin-bottom: 16px;
}
.video_section .video_title p {
  overflow: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  color: #7f7f7f;
  margin-bottom: 0;
  text-align: justify;
}

/* SUBSCRIBE NOW */
.subscribe_now {
  background: url("../img/bg/subscribe_bg.jpg") no-repeat center;
  background-size: cover;
  margin-bottom: 40px;
}
.subscribe_now h4 {
  text-align: center;
}
.subscribe_now p {
  /*text-align: justify; */
}
.subscribe_now.innovation {
  background-image: url("../img/bg/subscribe_bg_2.jpg");
}
.subscribe_now.innovation .content {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.subscribe_now.hotelier_in_numbers {
  background-image: url("../img/bg/in_coins.jpg");
}
.subscribe_now.hotelier_in_numbers p {
  text-align: center;
}
.subscribe_now.hotelier_in_numbers .content {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30;
}
.subscribe_now.hotelier_in_numbers .content > div {
  width: 100%;
  min-width: 80%;
  text-align: center;
  padding: 35px;
}
.subscribe_now.hotelier_in_numbers .content > div p {
  font-size: 16px;
}
.subscribe_now.hotelier_in_numbers .content > div span {
  font-weight: 600;
}
.subscribe_now.hotelier_in_numbers .content > div p.footnote span {
  font-size: 12px;
  text-align: right;
  font-weight: 400;
}
.subscribe_now .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 24px 16px;
}
.subscribe_now .content > div {
  background-color: rgba(252, 248, 252, 0.9);
  padding: 67px 62px;
  max-width: 550px;
  position: relative;
}
.subscribe_now .content > div h4,
.subscribe_now .content > div p {
  position: relative;
  font-size: 18px;
  line-height: 1.2;
  color: #1d1350;
}
.subscribe_now .content > div p {
  margin-bottom: 15px;
}
.subscribe_now h4 {
  text-transform: uppercase;
  font-family: "UbuntuMedium";
  margin-bottom: 16px;
}
.subscribe_now p {
  font-family: "PromptRegular";
}
.subscribe_now .btn {
  position: absolute;
  right: 20px;
  bottom: 21px;
}

/* Token Sales */
.sales_wrap .section_header {
  margin-bottom: 30px;
}

.sales {
  background: #1d1350;
}
.sales .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
}
.sales .content > div {
  width: -webkit-calc((100% - 6px)/3);
  width: calc((100% - 6px) / 3);
}
.sales .content > div:nth-child(1) h5 {
  margin-bottom: 30px;
}
.sales .content > div:nth-child(1) .progress:after {
  width: 70%;
}
.sales h5 {
  font: 16px/1 "UbuntuMedium";
  text-transform: uppercase;
  margin-bottom: 30px;
}
.sales p {
  font: 16px/1 "UbuntuLight";
}
.sales .progress {
  width: 100%;
  height: 5px;
  background-color: #fff;
  position: relative;
  margin-bottom: 30px;
}
.sales .progress:after {
  left: 0;
  top: 0;
  height: 100%;
  background-color: #6c1a74;
}

/* PLATFORM */
#platform img {
  width: 100%;
  margin: 0;
}

/* BENFITS */
.benefits {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.benefits .benefit {
  width: 25%;
  background-color: #fff;
  padding: 35px;
}
.benefits .benefit.benefit1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: no wrap;
  -ms-flex-wrap: no wrap;
  flex-wrap: no wrap;
  width: 50%;
  background-color: #1d1350;
}
.benefits .benefit.benefit1 div {
  width: 60%;
}
.benefits .benefit.benefit1 h4 {
  color: #fff;
  margin-bottom: 7px;
}
.benefits .benefit:nth-child(2),
.benefits .benefit:nth-child(5),
.benefits .benefit:nth-child(7) {
  background-color: #fcf8fc;
}
.benefits h4 {
  font: 16px/1.2 "PromptRegular";
  color: #041b2d;
  text-align: left;
}
.benefits img {
  display: block;
  margin-bottom: 8px;
  width: 100px;
}
.benefits svg {
  width: 50%;
}

.join {
  background-color: #f9f6fb;
  padding: 30px;
  text-align: center;
}
.join p {
  font: 18px/1.2 "UbuntuMedium";
  text-transform: uppercase;
  margin-bottom: 16px;
  color: #1d1350;
}

/* COIN, BONUSES */
.coin,
.bonuses {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.coin > div,
.bonuses > div {
  width: -webkit-calc(50% - 16px);
  width: calc(50% - 16px);
  margin-bottom: 32px;
}
.coin .coin_info,
.coin .bonus_info,
.bonuses .coin_info,
.bonuses .bonus_info {
  padding: 88px 35px;
  background-color: #f9f6fb;
  color: #1d1350;
}
.coin .coin_info h6,
.coin .bonus_info h6,
.bonuses .coin_info h6,
.bonuses .bonus_info h6 {
  text-transform: uppercase;
  font: 18px/1.2 "UbuntuMedium";
  margin-bottom: 16px;
}
/* .coin .coin_info p,
.coin .bonus_info p,
.bonuses .coin_info p,
.bonuses .bonus_info p {
  font: 16px/1.33 "UbuntuLight";
  text-align: justify;
} */
.coin .coin_info p {
  font-size: 17px;
  text-align: justify;
  color: #333;
}
.coin .coin_info h6 {
  margin-bottom: 5px;
  font-weight: 700 !important;
}
.coin .ticket,
.bonuses .ticket {
  background: #6c1a74;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.coin .ticket:before,
.bonuses .ticket:before {
  border-color: white;
}
.coin .ticket.total_tokens,
.bonuses .ticket.total_tokens {
  background: url("../img/bg/coin_bg.jpg") no-repeat center;
  background-size: cover;
}
.coin .ticket p,
.bonuses .ticket p {
  margin-bottom: 12px;
  font: 16px/1.2 "PromptRegular";
  text-align: center;
}
.coin .ticket p.ticket_title,
.bonuses .ticket p.ticket_title {
  font: 18px/1.2 "UbuntuMedium";
  text-transform: uppercase;
  margin-bottom: 12px;
}
.coin .ticket p span,
.bonuses .ticket p span {
  font-size: 36px;
}
.coin .ticket span,
.bonuses .ticket span {
  font: 12px/1.2 "UbuntuLight";
  margin-bottom: 4px;
}

.token_table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}
.token_table td,
.token_table th {
  width: -webkit-calc(65%/3);
  width: calc(65% / 3);
  text-align: left;
  border-bottom: 1px solid rgba(29, 19, 80, 0.2);
  padding: 25px 20px 25px 0;
  color: #1d1350;
  font: 16px/1 "UbuntuMedium";
}
.token_table th {
  border-color: #6c1a74;
}
.token_table td:first-child,
.token_table th {
  text-transform: uppercase;
  font: 18px/1 "UbuntuLight";
}
.token_table td:first-child {
  width: 35%;
}
.token_table.bonus td,
.token_table.bonus th {
  width: auto;
}
.token_table.bonus td:first-child,
.token_table.bonus th {
  font: 16px/1 "PromptRegular";
}
.token_table.bonus tr:first-child td {
  font: 14px/1 "UbuntuLight";
}
.token_table.bonus caption {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #6c1a74;
  padding: 20px 20px 20px 0;
  color: #1d1350;
  font: 16px/1 "UbuntuMedium";
  text-transform: uppercase;
}
.token_table.bonus caption span {
  font: 16px/1 "PromptRegular";
  text-transform: none;
  margin-top: 5px;
  display: block;
}

/* BONUSES */
.bonuses .bonus_info {
  padding: 35px;
}

.bonuses .ticket {
  padding: 52px;
}
.bonuses .ticket p:last-child {
  margin-bottom: 0;
}

.bonuses .total_bonus {
  background: #1d1350;
}

.mark {
  font-size: 14px;
  color: #041b2d;
}

/* SUBSCRIBE FORM */
.subscribe_form {
  background: url("../img/bg/subscribe_form_bg.jpg") no-repeat center;
  background-size: cover;
  padding: 100px 20px;
  color: #fff;
  text-align: center;
}
.subscribe_form > div {
  padding: 80px 40px 100px;
  max-width: 920px;
}
.subscribe_form > div > * {
  max-width: 540px;
  margin: 0 auto;
  position: relative;
}
.subscribe_form > div:before {
  border-color: #fff;
}
.subscribe_form h4 {
  font: 16px/1 "UbuntuMedium";
  margin-bottom: 16px;
  text-transform: uppercase;
}
.subscribe_form p {
  margin-bottom: 30px;
  font: 16px/1.2 "PromptRegular";
  font-weight: 600;
  text-transform: uppercase;
}
.subscribe_form form {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.subscribe_form form input {
  border: none;
  outline: none;
  width: 70%;
  font: 18px/1 "PromptRegular";
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
}
.subscribe_form form button {
  width: 30%;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
}
.subscribe_form form button.complete {
  background-image: url("../img/icons/complete.svg");
}
.subscribe_form form button.complete span {
  opacity: 0;
}

/* CHART */
.distribution_wrap {
  background-color: #f9f6fb;
  padding: 80px 0 40px;
}

.distribution {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}
.distribution > div {
  width: -webkit-calc(50% - 16px);
  width: calc(50% - 16px);
}
.distribution img {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: block;
}
.distribution .distribution_info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.distribution .distribution_info > div {
  margin-bottom: 35px;
  position: relative;
  padding-left: 20px;
}
.distribution .distribution_info > div:after,
.distribution .distribution_info > div:before {
  bottom: 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  left: 0px;
  width: 7px;
  height: 100%;
}
.distribution .distribution_info > div:last-child {
  margin-bottom: 0;
}
.distribution .distribution_info > div.sale:before {
  background-color: rgba(29, 19, 80, 0.2);
}
.distribution .distribution_info > div.sale:after {
  background-color: #1d1350;
  height: 70%;
}
.distribution .distribution_info > div.sale_later:before {
  background-color: rgba(68, 24, 123, 0.2);
}
.distribution .distribution_info > div.sale_later:after {
  background-color: #44187b;
  height: 50%;
}
.distribution .distribution_info > div.team:before {
  background-color: rgba(111, 0, 209, 0.2);
}
.distribution .distribution_info > div.team:after {
  background-color: #6f00d1;
  height: 30%;
}
.distribution .distribution_info > div.advisors:before {
  background-color: rgba(102, 31, 153, 0.2);
}
.distribution .distribution_info > div.advisors:after {
  background-color: #661f99;
  height: 20%;
}
.distribution .distribution_info > div.bonus:before {
  background-color: rgba(208, 208, 208, 0.2);
}
.distribution .distribution_info > div.bonus:after {
  background-color: #d0d0d0;
  height: 15%;
}
.distribution .distribution_info .amount {
  font: 30px/1 "UbuntuMedium";
  color: #041b2d;
  margin-bottom: 12px;
}
.distribution .distribution_info .desc {
  color: #545b61;
  font: 14px/1 "UbuntuLight";
}

/* ROADMAP */
.roadmap {
  margin: 40px 20px;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
}
.roadmap .roadmap_item_title,
.roadmap li {
  padding-left: 28px;
  position: relative;
}
.roadmap .roadmap_item_title:before,
.roadmap li:before {
  top: 4px;
  left: 2px;
  width: 11px;
  height: 11px;
}
.roadmap .roadmap_item_title {
  font: 16px/1.2 "UbuntuMedium";
  color: #041b2d;
  margin-bottom: 28px;
  padding-right: 20px;
}
.roadmap .roadmap_item_title:before {
  background-color: #1d1350;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.roadmap .roadmap_item_title:after {
  top: 50%;
  right: 0;
  width: 40%;
  max-width: 80px;
  height: 2px;
  background-color: #1d1350;
}
.roadmap li {
  font: 14px/1.3 "UbuntuLight";
  color: #545b61;
  margin-bottom: 12px;
}
.roadmap li.check {
  font-weight: bold;
}
.roadmap li.check:before {
  background: url("../img/icons/check.png") no-repeat center;
  background-size: contain;
}

/* TEAM */
#team,
#founding_partners,
#founding_advisor,
#advisors {
  margin-bottom: 0;
}
#team .member-wrap,
#founding_partners .member-wrap,
#founding_advisor .member-wrap,
#advisors .member-wrap {
  padding-bottom: 14px;
}
#team .content,
#founding_partners .content,
#founding_advisor .content,
#advisors .content {
  padding: 16px;
  padding-bottom: 30px;
}

.team.owl-carousel,
#member-info-popup {
  margin: 40px 20px;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
}
.team.owl-carousel .member,
#member-info-popup .member {
  margin-right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center #041b2d;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #f9f6fb;
  position: relative;
}
/* .team.owl-carousel .member .member-info p, #member-info-popup .member .member-info p {
      text-indent: 20px; } */
.team.owl-carousel .member .member-info a,
#member-info-popup .member .member-info a {
  color: #1d1350;
  word-break: break-all;
  font-size: 14px;
  margin-top: 10px;
  display: block;
}
.team.owl-carousel .member .info p,
#member-info-popup .member .info p {
  text-align: center;
  box-sizing: content-box;
}
.member-info p {
  text-align: left !important;
}
.team.owl-carousel .member .socials,
#member-info-popup .member .socials {
  margin-top: 0;
}
.team.owl-carousel .member .role,
#member-info-popup .member .role {
  height: 2em;
  margin-top: 0;
}
.team.owl-carousel .member .member-info,
#member-info-popup .member .member-info {
  margin-top: 10px;
  height: 3.1em;
  overflow: hidden;
  text-align: justify;
  padding: 0 10px;
}
.team.owl-carousel .photo,
#member-info-popup .photo {
  width: 160px;
  height: 160px;
  position: relative;
  margin: 10px 0 10px;
  padding-top: 0;
  overflow: hidden;
}
.team.owl-carousel .photo img,
#member-info-popup .photo img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.team.owl-carousel .info .name,
#member-info-popup .info .name {
  font: 18px/1.2 "UbuntuMedium";
  text-transform: uppercase;
  color: #041b2d;
  margin-bottom: 0;
}
.team.owl-carousel .info .role,
#member-info-popup .info .role {
  font: 16px/1.2 "UbuntuLight";
  color: #545b61;
  margin-bottom: 5px;
}
.team.owl-carousel .socials,
#member-info-popup .socials {
  position: relative;
  padding: 22px 0 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.team.owl-carousel .socials:before,
#member-info-popup .socials:before {
  top: 0;
  left: -7px;
  right: 0;
  margin: auto;
  width: 40px;
  height: 3px;
  background-color: #6c1a74;
}
.team.owl-carousel .socials li:not(:last-child),
#member-info-popup .socials li:not(:last-child) {
  margin-right: 10px;
}
.team.owl-carousel .socials li a,
#member-info-popup .socials li a {
  width: 26px;
  height: 24px;
  display: block;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #1d1350;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 17px;
}
.team.owl-carousel .socials li a.linkedin,
#member-info-popup .socials li a.linkedin {
  background-image: url("../img/icons/linkedin.svg");
}
.team.owl-carousel .socials li a.fb,
#member-info-popup .socials li a.fb {
  background-image: url("../img/icons/facebook.svg");
}
.team.owl-carousel .socials li a.telegram,
#member-info-popup .socials li a.telegram {
  background-image: url("../img/icons/telegram.svg");
}
.team.owl-carousel .socials li a:hover,
#member-info-popup .socials li a:hover {
  background-color: #251c7a;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* News */
.main_news {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.main_news img {
  display: block;
  width: 60%;
  height: 100%;
}
.main_news .news_info {
  width: 40%;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.main_news .news_info .btn {
  margin-left: 30px;
}
.main_news .news_content {
  width: 100%;
  padding: 50px 35px;
  background-color: #f9f6fb;
}
.main_news .news_content h4 {
  margin-bottom: 12px;
  text-align: left;
}
.main_news .news_content p {
  max-height: -webkit-calc(1em * 3 * 1.2);
  max-height: calc(1em * 3 * 1.2);
  text-align: justify;
  text-indent: 20px;
}

.news_content {
  padding: 35px;
  color: #041b2d;
  margin-bottom: 32px;
  width: 100%;
}
.news_content > p {
  text-indent: 20px;
}
.news_content h4 {
  text-transform: uppercase;
  font: 14px/1.2 "UbuntuMedium";
  margin-bottom: 4px;
  text-align: center;
}
.news_content p {
  font: 14px/1.2 "PromptRegular";
  overflow: hidden;
  text-align: justify;
}

.news_info {
  position: relative;
}
.news_info .date {
  font: 12px/1.2 "PromptRegular";
  color: #545b61;
  text-align: center;
}

.news {
  margin: auto;
}
.news img {
  /*height: 250px;*/
  -o-object-fit: cover;
  object-fit: cover;
}
.news .news_info {
  background-color: #f9f6fb;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: -webkit-calc(100% - 250px);
  height: calc(100% - 250px);
}
.news .news_info .date {
  margin-bottom: 16px;
}
.news .news_content {
  padding-bottom: 50px;
  margin-bottom: 0;
}
.news .btn {
  position: absolute;
  bottom: -17px;
  z-index: 2;
  margin: auto;
}
.news .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.news_item {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.news_item img {
  height: 37px;

  width: auto;
}

/* FAQ */
.faq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.faq_icon {
  padding: 0 35px;
  width: 33%;
}
.faq_icon h4 {
  text-transform: uppercase;
  font: 18px/1.33 "UbuntuMedium";
  margin-bottom: 40px;
  color: #041b2d;
  max-width: 220px;
  position: relative;
}
.faq_icon img {
  margin: 0;
  max-width: 227px;
  display: block;
}

.faq_list {
  width: 67%;
}
.faq_list li {
  padding: 0;
  margin-bottom: 8px;
  cursor: pointer;
}
.faq_list li.active .item:before {
  content: "-";
}
.faq_list .item {
  padding: 16px 40px 16px 0;
  margin-bottom: 16px;
  position: relative;
  font: 16px/1.2 "UbuntuMedium";
  color: #041b2d;
  border-bottom: 1px solid #041b2d;
}
.faq_list .item:before {
  content: "+";
  position: absolute;
  right: 20px;
  top: -webkit-calc(50% - 5px);
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  line-height: 0.7;
}
.faq_list .hint {
  display: none;
}
.faq_list .hint p {
  color: #545b61;
  font: 14px/1.2 "UbuntuLight";
}

/* FOOTER */
footer {
  position: relative;
  background-color: #1a092e;
  border-top: 1px solid #fff;
}
footer .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font: 16px/1.2 "PromptRegular";
  padding-top: 55px;
  padding-bottom: 30px;
  color: #fefefe;
}
footer .useful_links {
  margin: 0;
}
footer .useful_links ul li {
  text-align: right;
}
footer .logo {
  max-width: 168px;
  margin-bottom: 20px;
  display: block;
}
footer .logo img {
  display: block;
  margin-left: -20px;
}
footer .logo_icon {
  border: 1px solid #fff;
  width: 160px;
  height: 100%;
  padding: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-70px);
  -ms-transform: translateY(-70px);
  transform: translateY(-70px);
  margin-bottom: -70px;
  background-color: #1a092e;
}
footer .logo_icon img {
  width: 100%;
  max-width: 55px;
  display: block;
}
footer .contact_info p {
  max-width: 400px;

  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
footer .contact_info p a {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 7px;
}
footer .contact_info a {
  margin-bottom: 16px;
  color: #6c1a74;

  letter-spacing: 0.03em;
  display: block;
}
footer .contact_info h6 {
  font: 16px/1.2 "PromptRegular";
}
footer ul li {
  margin-bottom: 15px;
  text-align: right;
}
footer ul li a {
  -webkit-transition: color 0.2s;
  color: #fff;
  transition: color 0.2s;
}
footer ul li a:hover {
  color: #6c1a74;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
footer .copyright {
  background-color: #fff;
  padding-top: 20px;
}
footer .copyright .content {
  padding: 16px;
  font: 12px/1 "UbuntuLight";
  color: #545b61;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.socials {
  margin-top: 15px;
}
.socials li {
  display: inline-block;
}
.socials li:not(:first-child) {
  margin-left: 8px;
}
.socials li a {
  display: block;
  width: 32px;
  height: 32px;
  background: #6c1a74;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
}
.socials li a.fb {
  background-image: url("../img/social_icons/facebook.svg");
}
.socials li a.twitter {
  background-image: url("../img/social_icons/twitter.svg");
}
.socials li a.linkedin {
  background-image: url("../img/social_icons/linkedin.svg");
}
.socials li a.youtube {
  background-image: url("../img/social_icons/youtube.svg");
}
.socials li a.telegram {
  background-image: url("../img/social_icons/telegram.svg");
}
.socials li a.pinterest {
  background-image: url("../img/social_icons/pinterest.svg");
}
.socials li a:hover {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #8e209e;
}

/* Popup */
.popup_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 100;
  padding: 30px 16px;
  display: none;
}
.popup_wrap .popup {
  max-width: 900px;
  background-color: rgba(252, 248, 252, 0.9);
  position: relative;
  width: 100%;
}
.popup_wrap .popup .close-popup {
  position: absolute;
  bottom: -webkit-calc(100% + 5px);
  bottom: calc(100% + 5px);
  right: 0;
  width: auto;
  height: auto;
  font: 12px/1.2 "PromptRegular";
  display: block;
  outline: none;
  color: #fff;
  text-transform: uppercase;
}
.popup_wrap .popup form {
  -moz-placeholder-font: 14px/1 "PromptRegular";
  -moz-placeholder-color: #c0c3c7;
  -ms-input-placeholder-font: 14px/1 "PromptRegular";
  -ms-input-placeholder-color: #c0c3c7;
}
.popup_wrap .popup form ::-webkit-input-placeholder {
  font: 14px/1 "PromptRegular";
  color: #c0c3c7;
}
.popup_wrap .popup form ::-moz-placeholder {
  font: 14px/1 "PromptRegular";
  color: #c0c3c7;
}
.popup_wrap .popup form input,
.popup_wrap .popup form textarea {
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 18px;
  padding: 12px 20px;
  border: 1px solid #b2b2b2;
  color: #041b2d;
  font: 14px/1 "PromptRegular";
}
.popup_wrap .popup form input:focus,
.popup_wrap .popup form textarea:focus {
  border-color: #333333;
}
.popup_wrap .popup form input:-webkit-autofill,
.popup_wrap .popup form input:-webkit-autofill:hover,
.popup_wrap .popup form input:-webkit-autofill:focus,
.popup_wrap .popup form input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #041b2d !important;
  color: #041b2d !important;
}

/* Video Popup*/
.video_popup_wrap .popup,
.video_popup_wrap2 .popup {
  max-width: 820px;
  background-color: transparent;
}

.video_popup {
  width: 100%;
  max-width: 820px;
  margin: auto;
  position: relative;
  padding-bottom: 56.25%;
  height: 0px;
  overflow: hidden;
}
.video_popup iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Contact_us Popup */
#contact-form-popup .popup {
  width: 100%;
}

#contact-form-popup .popup-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 90vh;
}
#contact-form-popup .popup-content > div,
#contact-form-popup .popup-content form {
  width: 50%;
  padding: 90px 50px;
}
#contact-form-popup .popup-content > div .contact-title,
#contact-form-popup .popup-content form .contact-title {
  font: 32px/1.2 "UbuntuMedium";
  color: #1d1350;
  margin-bottom: 64px;
}
#contact-form-popup .popup-content > div {
  background: url("../img/bg/contact_bg.jpg") no-repeat center;
  background-size: cover;
}
#contact-form-popup .popup-content form {
  background-color: #fff;
}
#contact-form-popup .popup-content form textarea {
  min-height: 100px;
  resize: none;
}
#contact-form-popup .popup-content form button {
  min-width: 80%;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
}
#contact-form-popup .popup-content form button.complete {
  background-image: url("../img/icons/complete.svg");
}
#contact-form-popup .popup-content form button.complete span {
  opacity: 0;
}

#contact-form-popup .contacts p,
#contact-form-popup .contacts a {
  font: 16px/1.33 "PromptRegular";
  color: #1d1350;
  margin-bottom: 24px;
}

#contact-form-popup .contacts a {
  text-decoration: underline;
}

.read_more {
  background: #6c1a74;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  color: white;
  position: absolute;
  bottom: -14px;
  z-index: 99999;
  overflow: visible;
  border-radius: 4px;
}
.read_more:hover {
  background: #8e209e;
  color: white;
}

#member-info-popup {
  margin: 0;
  width: 100%;
}
#member-info-popup .popup-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 10px;
  max-height: 90vh;
  overflow: auto;
}
#member-info-popup .member-popup-left {
  background-color: #f9f6fb;
  width: 25%;
  margin-right: 10px;
}
#member-info-popup .member-popup-left .member {
  margin-right: 0;
}
#member-info-popup .member-popup-right {
  text-align: justify;
  width: 75%;
  padding: 20px;
  max-height: 500px;
  overflow: auto;
  background-color: #f9f6fb;
}
/* #member-info-popup .member-popup-right p {
      text-indent: 20px; } */
#member-info-popup .member-popup-right a {
  color: #1d1350;
  word-break: break-all;
  font-size: 14px;
  margin-top: 10px;
  display: block;
}

#partners .partner-wrap {
  padding-bottom: 14px;
}

.partner-div {
  margin-right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-color: #f9f6fb;
  padding: 10px;
  padding-bottom: 30px;
  min-height: 240px;
  position: relative;
}
.partner-div .partner-description {
  padding: 0 5px;
  text-align: justify;
  height: 88px;
  overflow: hidden;
}
.partner-div .partner-description p {
  text-indent: 20px;
}

.mobile_menu {
  position: fixed;
  top: 53px;
  left: 0;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px 30px;
  z-index: 100;
  display: none;
}
.mobile_menu li {
  text-align: left;
  color: white;
  margin-bottom: 10px;
}
.mobile_menu li a:hover {
  color: #76297d;
}

.ticket .hard_cap_line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 220px;
}
.ticket .hard_cap_line p {
  font-size: 14px;
}
.ticket .hard_cap_line p:first-child {
  text-align: left;
}
.ticket .hard_cap_line p:last-child {
  text-align: right;
}

.hard_cap_add {
  display: none;
}

@media only screen and (max-width: 1140px) {
  header nav ul li {
    margin: 0 5px;
  }
  .topline_wrap .lang {
    margin-right: 10px;
  }
  .topline_wrap .btn.transparent {
    margin-right: 10px;
  }
  .topline_wrap .btn.account {
    padding: 19px 20px;
  }
  .header_bottom .timer_wrap {
    width: 100%;
    float: none;
  }
  .header_bottom > div:nth-child(1) {
    padding: 40px 16px;
  }
  .benefits .benefit {
    padding: 30px;
  }
  .team.owl-carousel {
    margin: 40px 20px;
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
  }
  .team.owl-carousel .member {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .team.owl-carousel .socials:before {
    right: 0;
    margin: auto;
  }
}

@media only screen and (max-width: 992px) {
  header nav {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    display: none;
  }
  header nav ul {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;
  }
  header nav ul li {
    padding: 10px 0;
  }
  .toggle_menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 62px;
  }
  .header_bottom > div {
    width: 65%;
  }
  .video_section .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .video_section .video {
    width: 100%;
  }
  .video_section .video_title {
    width: 100%;
  }
}

@media only screen and (max-width: 870px) {
  .video_section .video .play {
    margin-bottom: 6%;
  }
  .coin,
  .bonuses {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .coin > div,
  .bonuses > div {
    width: 100%;
    margin-bottom: 0;
  }
  .coin .coin_info,
  .coin .bonus_info,
  .bonuses .coin_info,
  .bonuses .bonus_info {
    padding: 10%;
  }
  .token_table {
    display: none;
  }
  .faq {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .faq_icon {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .faq_icon h4 {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 0;
  }
  .faq_icon img {
    width: 60px;
    height: 60px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    margin-right: 20px;
  }
  .faq_list {
    width: 100%;
  }
  .hard_cap_add {
    display: block;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .header_content .btn_wrap {
    display: block;
    margin: 0 auto !important;
  }
  .header_bottom > div {
    width: 100%;
  }
  .benefits .benefit {
    padding: 20px;
    width: 33%;
    background-color: #fcf8fc;
  }
  .benefits .benefit.benefit1 {
    width: 100%;
  }
  #contact-form-popup .popup-content {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-height: -webkit-calc(90vh - 70px);
    max-height: calc(90vh - 70px);
    overflow: auto;
  }
  #contact-form-popup .popup-content > div,
  #contact-form-popup .popup-content form {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  #member-info-popup .popup-content {
    max-height: -webkit-calc(90vh - 75px);
    max-height: calc(90vh - 75px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
  }
  #member-info-popup .member-popup-left {
    width: 100%;
    margin-right: 0;
  }
  #member-info-popup .member-popup-right {
    width: 100%;
    max-height: 100%;
    overflow: visible;
    margin-top: 10px;
  }
  .popup-youtube {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .header_content h1,
  .header_content h2 {
    text-align: center;
  }
  .header_content .btn_wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .main_news {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main_news img {
    width: 100%;
  }
  .main_news .news_info {
    width: 100%;
  }
  footer .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
  }
  footer .useful_links {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
  footer .useful_links ul li {
    text-align: center;
  }
  footer .logo_icon {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    margin-bottom: 0;
    margin-top: -37px;
    margin-left: 0;
  }

  footer .contact_info {
    text-align: left;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  footer .contact_info a {
    margin: 0 auto 16px auto;
  }
  footer .copyright {
    text-align: center;
  }
}
.award_img_img {
  height: 90px;
}
@media only screen and (max-width: 550px) {
  .topline_wrap .topline {
    flex-flow: initial;
  }
  .topline_wrap .btn.account {
    font-size: 0.8rem;
  }
  .mobile_menu ul li a {
    color: #fff;
  }
  .section_header {
    text-align: center;
    padding: 0 10px;
    font-size: 20px;
    margin-bottom: 25px;
    margin-top: 15px !important;
  }
  .header_logo {
    margin: 0;
  }
  .header_logo .logo {
    padding: 10px 22px;
  }
  .header_logo .logo img {
    height: 33px;
  }
  .topline_bg .toggle_menu {
    padding: 0 5px;
    height: 53px;
    min-width: 30px;
    background: transparent;
  }
  .main-page-service-block h4 {
    font-size: 14px;
  }
  .ul_block {
    flex-wrap: wrap;
  }
  ul.ul_block li {
    width: 30%;
    /* margin-bottom: 10px; */
  }
  .main-page-service-block h2 {
    font-size: 14px;
  }
  .topline_wrap .btn.account {
    padding: 15px 10px !important;
  }
  .topline_wrap .btn.subscribe_link {
    display: none;
    font-size: 12px;
  }
  .topline_wrap .lang {
    margin: 0 5px;
  }
  .header_content #watch_interview {
    padding: 10px;
  }
  .benefits .benefit {
    width: 50%;
  }
  .subscribe_form form {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .subscribe_form form input {
    width: 100%;
  }
  .subscribe_form form button {
    width: 100%;
  }
  .distribution {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .distribution > div {
    width: 100%;
  }
  .distribution img {
    margin-bottom: 30px;
  }
  .popup_wrap .popup {
    max-height: -webkit-calc(90vh - 55px);
    max-height: calc(90vh - 55px);
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .header_bottom .timer_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header_bottom .timer {
    width: 90%;
  }
  .header_bottom .price {
    width: 90%;
    margin-top: 20px;
  }
  .header_bottom .price p {
    border-left: 1px solid #fff;
  }
  .ico_timer {
    padding: 20px;
  }
  .video_section .video .logo_icon {
    display: none;
  }
  .sales .content > div:nth-child(1) h5 {
    margin-bottom: 14px;
  }
  .benefits .benefit {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.timer-container {
  color: #fff;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 50px 35px;
  border-radius: 8px;
  width: 555px;
  position: relative;
  top: 0;
  margin-bottom: 30px;
}

.timer-container:before {
  content: "";
  position: absolute;
  top: 22px;
  bottom: 22px;
  left: 22px;
  right: 22px;
  margin: auto;
  border: 1px solid #fff;
  width: calc(100% - 44px);
  height: calc(100% - 44px);
  z-index: 0;
}

.timer-container ul li {
  display: inline-block;
  list-style-type: none;
  padding: 10px;
  text-transform: uppercase;
}

.timer-container ul li span {
  display: block;
  font-size: 27px;
  font-weight: 700;
}
.top-banner-head {
  position: relative;
  flex-flow: inherit;
  flex-grow: inherit;
  display: flex !important;
  justify-content: flex-start;
}
.left-top-col {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.left-top-col h1 {
  font-size: 38px;
}
.moretext {
  display: none;
  transition: all 0.2s ease-in-out;
}
.right-top-col {
}
.right-message {
  top: 80px;
  width: 420px;
  height: auto;
  display: inline-table;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 35px 20px 35px;
  border-radius: 8px;
  position: relative;
}
.right-message p {
  padding: 0 15px;
}
.right-message:before {
  content: "";
  position: absolute;
  top: 22px;
  bottom: 22px;
  left: 22px;
  right: 22px;
  margin: auto;
  border: 1px solid #fff;
  width: calc(100% - 44px);
  height: calc(100% - 44px);
  z-index: 0;
}
.right-message .list-message {
  font-size: 14px;
  text-align: left;
  padding-left: 50px;
}
.right-message .list-message li:before {
  content: "-";
  padding-right: 5px;
}
.right-top-col .coin_img {
  height: 90px;
}
.download_btn {
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
}
.download_btn img {
  height: 80px;
}
.private-sale-main .header_content {
  margin-top: 150px;
}
.private-sale-presale .timer-container {
  top: -50px;
  right: -150px;
  min-width: 445px;
}
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: "Show more";
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: "Show less";
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5em;
  color: #666;
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}
@media (max-width: 767px) {
  .award_img_img {
    height: auto;
    width: 100%;
  }
  .media-quote-div {
    margin: 0 0 !important;
    padding-top: 25px !important;
  }
  .quote-text {
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 0 10px;
  }
  .media-logo {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .video_section .content {
    padding-top: 0;
  }
  p {
    font-size: 14px !important;
  }
  .benefits .benefit.benefit1 div {
    width: 100%;
  }
  .team.owl-carousel {
    margin-bottom: 0 !important;
  }
  .faq ul {
    padding: 0px 0 !important;
  }
  .section_header {
    text-align: center !important;
  }
  .box {
    width: 100%;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .card-col img {
    height: 160px;
    margin-top: 20px;
    width: 100%;
    object-fit: contain;
  }
  .strip_section {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .video_section .video_title li,
  .media-quote-div {
    padding-bottom: 0 !important;
  }
  .hotelier_in_numbers {
    margin-bottom: 35px !important;
  }
  .content {
    padding: 32px 0px;
  }
  .our-brands {
    margin-bottom: -45px !important;
  }
  .b-text {
    font-size: 2.5em !important;
  }
  .s-j {
    font-size: 1em !important;
  }
  .career-ad {
    width: 100%;
  }
  .timer-container {
    width: 100%;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .timer-container ul li span {
    font-size: 1.5rem;
  }
  .private-sale-main .header_content {
    margin-top: 80px;
  }
  .private-sale-presale .timer-container {
    top: 150px;
    position: relative;
  }
  .right-message {
    width: 95%;
    margin: 0 auto;
    display: block;
  }
  .btn_section_sec .wp_btn_wrap {
    max-width: 100% !important;
  }
  .btn_section_sec .wp_btn_wrap .wpBtn {
    font-size: 13px !important;
  }
  .t_award h2 {
    font-size: 18px;
  }
  .one_travel_wrap.boom_wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  section.vide_sec_sssssss .coin_info {
    width: 100% !important;
  }
  .video.video_bbbbb {
    width: 100% !important;
    margin-top: 25px;
  }
  img.graph_img_xx {
    margin-left: 0;
    width: 100%;
    height: auto !important;
  }
}
@media (max-width: 453px) {
  .timer-container ul li {
    padding: 10px;
  }
}
@media (max-width: 405px) {
  .timer-container ul li {
    padding: 7px;
  }
  .download_btn img {
    height: 65px;
  }
}
@media (max-width: 383px) {
  .topline_wrap .btn.account {
    padding: 6px 11px 7px !important;
  }
}
@media (max-width: 381px) {
  .timer-container ul li {
    padding: 5px;
  }
}
.link_btn {
  background: #6a0074;
  color: #fff;
  padding: 10px;
  top: 10px;
  border-radius: 4px;
  margin-right: -15px;
}
.link_btn:hover {
  background: #6a0074;
  color: #fff;
}
.travolier_bg {
  min-height: 450px;
  /* background-image: url('../img/bg/travel.jpg') !important; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/bg/travel.jpg") !important;
  background-size: cover;
  background-position: center;
}
.member_benefits {
  margin-left: 15px;
  margin-top: 5px;
  list-style-type: circle;
}
.app_download_section {
  background-image: url("../img/bg/travolier_2.jpg") !important;
  background-size: cover;
  background-position: inherit;
}
.app_download_section .btn {
  position: inherit;
  margin-top: 30px;
}

.private_bg {
  min-height: 450px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../img/bg/sky.jpg") !important;
  background-size: cover;
  background-position: center;
}
.left_col {
  padding: 0 130px;
}
.left_col p,
.right_col p {
  font-size: 1.5em;
}
.left_col hr {
  margin: 15px 0;
}

.popup-youtube {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-youtube:hover {
  color: #fff;
}
.video_bbbbb::before {
  content: "";
  position: absolute;
  top: 22px;
  bottom: 22px;
  left: 12px;
  right: 22px;
  margin: auto;
  border: 1px solid #fff;
  width: calc(100% - 23px);
  height: calc(100% - 25px);
  z-index: 0;
}
section.vide_sec_sssssss {
  margin-bottom: 0;
  padding: 60px 0 40px;
  background: #fff;
}

section.vide_sec_sssssss .coin_info {
  background: transparent;
  background: transparent;
  padding: 0;
  width: 50%;
  display: flex;
  align-items: center;
  text-align: justify;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.video.video_bbbbb {
  width: 30%;
}
.video.video_bbbbb > img {
  width: 100%;
  display: block;
  height: 220px;
  object-fit: cover;
  object-position: 48% 0%;
}
.four_points_sec {
  background: #fff;
  padding: 50px 0;
  margin-bottom: 0;
}

section.graph_section {
  padding: 60px 0;
  margin-bottom: 0;
  background: #f3f4f6;
}

.how_work_sec img {
  width: 100%;
}
.travoilier-main .section_header_line,
.private-sale-main .section_header_line {
  margin-top: 15px;
  margin-bottom: 30px;
}
img.right-col-image {
  height: 345px;
  object-fit: cover;
}
.app_download_section.hotelier_in_numbers .content > div {
  width: 60%;
  min-width: 60%;
}

.luggage_tag {
  position: absolute;
  right: -30px;
  top: 52px;
  transform-origin: 65.15% 0%;
  animation: swinging 3.5s ease-in-out normal;
}
.luggage_tag img {
  height: 224px;
}
.luggage_tag span.b {
  position: absolute;
  top: 90px;
  right: 2px;
  font-size: 15px;
  font-weight: 800;
  color: #6c1a74;
  line-height: 17px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;

  text-align: center;
}
.luggage_tag span.t {
  margin-top: 10px;
}
.luggage_tag span.a {
  display: block;
  text-align: center;
  text-transform: uppercase;
}
.luggage_tag small {
  position: absolute;
  bottom: 32px;
  left: 18px;
  font-size: 11px;
}
.luggage_tag img.trav-logo {
  height: 45px;
  background: #6c1a74;
  margin-top: 0;
  padding: 7px 0;

  width: 93%;
  object-fit: contain;
}
@keyframes swinging {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.slick-slide {
  margin: 0px 5px;
}

.slick-slide img {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  object-fit: contain;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.video {
  position: relative;
}
.video > img {
  width: 100%;
  display: block;
  height: 423px;
  object-fit: cover;
  object-position: 48% 0%;
}
.t_award {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.t_award h2 {
  color: #722c8e;
  font-family: "Prompt", sans-serif !important;
  font-weight: 800 !important;
  font-size: 30px;
  margin-top: 15px;
}
.section_title {
  font-family: "Prompt", sans-serif !important;
  color: #333333;
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 700 !important;
  text-align: left;
  margin-bottom: 40px;
  border-bottom: 2px solid;
  padding-bottom: 7px;
}

.four_points_sec .four_container .section_title {
  font-size: 16px;
  margin-bottom: 40px;
  height: 50px;
}
.c_img_content ul li strong {
  margin-bottom: 5px;
  font-size: 18px;
  display: inline-block;
  margin-top: 10px;
}

.four_points_sec p span {
  font-weight: 700 !important;
  font-size: 18px;
  margin-right: 5px;
}
.h_t_l_m_sec {
  padding: 60px 0 60px;
  margin-bottom: 0;
}
.h_t_l_m_sec .content {
  padding-bottom: 0;
}
.one_sec {
  background-color: #f3f4f6;
  padding: 60px 0;
  margin-bottom: 0;
}
/* .video a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
} */
.video .play {
  width: 32px;
  margin-bottom: 28px;
}
.video .logo_text {
  width: 220px;
  display: block;
}
.video .video_wrap {
  width: calc(100% - 25px);
  max-width: 820px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  padding-bottom: 400px;
  height: 0px;
  border: 1px solid #fff;
}
.video .video_wrap iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
}
.strip_section {
  padding-bottom: 30px;
}
.strip_section .section_header_line {
  margin-top: 10px;
  margin-bottom: 30px;
}
.ui.grid.strip {
  padding: 0 25px;
  margin: 0;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0;
}
.ui.grid > .column:not(.row) {
  padding-top: 5px;
  padding-bottom: 0;
  position: relative;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: top;
}
.ui.column.grid > [class*="ten wide"].column,
.ui.grid > .column.row > [class*="ten wide"].column,
.ui.grid > .row > [class*="ten wide"].column,
.ui.grid > [class*="ten wide"].column {
  width: 62.5%;
}
.ui.grid > [class*="six wide"].column {
  width: 37.5%;
}
.card-col img {
  height: 280px;
  margin-top: -100px;
  margin-left: 30px;
}
.box {
  padding: 25px;
  text-align: center;
  background: #f7f7f7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
}
.strip .btn {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .our-network-main .member-wrap {
    margin: 0px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1180px) {
  .top-banner-head {
    display: block !important;
  }
  .right-message {
    top: 10px;
  }
}
@media (max-width: 1270px) {
  .luggage_tag {
    display: none;
  }
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 0.9rem;
}

.text-xsmall {
  font-size: 0.8rem;
}

.text-gray {
  color: #868e96;
}

.text-uppercase {
  letter-spacing: 0.2em;
}

.lh-2 {
  line-height: 2;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
}

body {
  overflow-x: hidden;
  color: #333333 !important;
}

i {
  display: inline-block;
}

span {
  display: inline-block;
}

.mt-30px {
  margin-top: 30px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

i[class*="icon-"] {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

table {
  font-size: 0.9em;
  color: #666;
}

.btn-xs {
  font-size: 0.7em !important;
  padding: 2px 5px !important;
  border-radius: 2px !important;
}

.badge {
  font-weight: 400;
  border-radius: 0;
}

.badge.badge-primary {
  background: #6c1a74;
}

.page {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  background-color: #f4f7fa;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 0;
}
.inner_header {
  height: 200px;
  background: #1d1350;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
}
.inner_main .page {
  top: 200px;
  padding-top: 30px;
}
.sticky .inner_header {
  height: 60px;
}
.sticky .user_details_col {
  display: none;
}
.page.active {
  width: calc(100% - 70px);
}

h2 {
  color: #555;
  font-weight: 400;
}

.breadcrumb-holder {
  background: #eceeef;
}

.breadcrumb-holder .breadcrumb {
  padding: 15px 0;
  font-size: 0.85em;
  font-weight: 300;
  color: #999;
  margin-bottom: 0;
}

.breadcrumb-holder .breadcrumb a:hover,
.breadcrumb-holder .breadcrumb:focus {
  text-decoration: none;
}

.breadcrumb-holder .breadcrumb .container {
  padding: 0;
}

.card {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  margin-bottom: 0;
}

.card:not([class*="text"]) p {
  color: #888;
}

.display {
  font-weight: 400 !important;
}

.section-padding {
  padding: 3rem 0;
}

.section-no-padding-bottom {
  padding-bottom: 0;
}

section header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .container-fluid {
    padding: 0 3rem;
  }
}

.list-group-item {
  border-right: 0;
  border-left: 0;
}

.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.tile-link {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 30;
}

/*
* ==========================================================
*     Main NAVBAR
* ==========================================================
*/
nav.navbar {
  background: #1d1350;
  padding: 0.8rem 0;
  /* Navbar Brand --------- */
  /*  Dropdowns ------------ */
  /* Toggle Button ---------- */
}

nav.navbar .side-navbar-wrapper {
  width: 100%;
  height: 100%;
}

nav.navbar .container-fluid {
  width: 100%;
}

nav.navbar .navbar-holder {
  width: 100%;
}

nav.navbar a {
  font-size: 0.8rem;
  color: #fff;
  position: relative;
}

nav.navbar .badge {
  position: absolute;
  top: 0;
  right: 0;
}

nav.navbar a.language span {
  margin-left: 0.2rem;
  vertical-align: middle;
}

nav.navbar a.logout i {
  margin-left: 5px;
}

nav.navbar small {
  font-size: 0.75rem;
  color: #999;
}

nav.navbar a.navbar-brand {
  margin-left: 10px;
}
nav.navbar a.navbar-brand img {
  height: 50px;
  margin-left: -30px;
}
nav.navbar .brand-text {
  text-transform: uppercase;
}

nav.navbar .brand-text strong {
  font-size: 1.5em;
}

nav.navbar .nav-menu {
  margin-bottom: 0;
}

nav.navbar .dropdown-toggle::after {
  border: none !important;
  content: "\f107";
  vertical-align: baseline;
  font-family: "FontAwesome";
  margin-left: 0.3rem;
}

nav.navbar .dropdown-menu {
  right: 0;
  left: auto;
  float: left;
  margin-top: 16px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
  padding: 1rem;
}

nav.navbar .dropdown-menu .dropdown-item {
  color: #666;
  padding: 10px;
}

nav.navbar .dropdown-menu .dropdown-item:active {
  color: #fff;
}

nav.navbar .dropdown-menu .dropdown-item:active span,
nav.navbar .dropdown-menu .dropdown-item:active small {
  color: #fff;
}

nav.navbar .dropdown-menu .dropdown-item span {
  color: #777;
}

nav.navbar .dropdown-menu .dropdown-item i {
  margin-right: 5px;
}

nav.navbar .msg-body span,
nav.navbar .msg-body strong {
  display: block;
}

nav.navbar .msg-body span {
  font-size: 0.85em;
}

nav.navbar .msg-profile {
  margin-right: 10px;
  width: 50px;
}

nav.navbar .notification-content {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

nav.navbar a.menu-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #292929;
  color: #fff;
  text-align: center;
  padding: 0 !important;
}

nav.navbar a.menu-btn i[class*="icon-"] {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

nav.navbar a.menu-btn:hover {
  background: #6c1a74;
}

/*  NAVBAR MEDIAQUERIES ---------------------------------- */
@media (max-width: 575px) {
  nav.navbar .dropdown {
    position: static;
  }
  nav.navbar .dropdown-menu {
    width: 90vw;
    right: 2.5vw;
    left: 2.5vw;
    margin: 0 auto;
  }
  nav.navbar .notification {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  nav.navbar .dropdown-menu strong {
    font-weight: 400;
  }
  nav.navbar .dropdown-menu a {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*     SIDE NAVBAR
* ==========================================================
*/
.side-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  width: 200px;
  text-align: left;
  background: #393836;
  color: #fff;
  z-index: 999;
  overflow-y: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /*  Side navbar small ------------------------ */
}

.side-navbar .scrolled-wrapper {
  width: 100%;
  height: 100%;
}

.side-navbar.hide {
  left: -70px;
}

.side-navbar a.brand-small {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0;
}

.side-navbar a.brand-small:hover,
.side-navbar a.brand-small:focus {
  background: none;
}

.side-navbar a.brand-small strong {
  font-size: 1.5rem;
}

.side-navbar li {
  position: relative;
}

.side-navbar li a {
  position: relative;
  font-size: 0.9rem;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  width: 100%;
  padding: 12px;
}

.side-navbar li a:focus,
.side-navbar li a:hover,
.side-navbar li a[aria-expanded="true"] {
  background: #6c1a74;
  color: #fff;
  text-decoration: none;
}

.side-navbar li a[data-toggle="collapse"]::before {
  content: "\f104";
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 20px;
}

.side-navbar li a[aria-expanded="true"]::before {
  content: "\f107";
  color: #fff;
}

.side-navbar li.active > a {
  background: #6c1a74;
}

.side-navbar li li.active > a {
  background: #2d9f50 !important;
}

.side-navbar li ul {
  background: #292929;
  position: relative;
  min-width: 100%;
  left: 0;
  padding-left: 0;
}

.side-navbar li ul a {
  padding-left: 20px;
  font-size: 0.85rem;
}

.side-navbar li ul.collapse a {
  border-left: 2px solid #6c1a74;
}

.side-navbar li ul.collapse a:hover {
  background: #121212;
}

.side-navbar .sidenav-header {
  background: #292929;
  padding: 14px;
  margin-bottom: 10px;
}

.side-navbar .sidenav-header img {
  width: 50px;
  height: 50px;
}

.side-navbar .sidenav-header span {
  font-size: 0.8em;
  color: #666;
}

.side-navbar .sidenav-header h2 {
  margin: 10px 0 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.side-navbar .sidenav-heading {
  font-weight: 700;
  color: #adb5bd;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.side-navbar i[class*="icon-"] {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.side-navbar span {
  display: inline-block;
}

.side-navbar i {
  font-size: inherit;
  margin-right: 10px;
}

.side-navbar .badge {
  display: inline-block;
  font-size: 0.6em;
  vertical-align: middle;
}

.side-navbar .brand-small {
  display: none;
}

.side-navbar.shrink {
  width: 70px;
  text-align: center;
}

.side-navbar.shrink .brand-small {
  display: block;
}

.side-navbar.shrink .sidenav-header-inner {
  display: none;
}

.side-navbar.shrink .sidenav-heading {
  margin-left: 0;
  font-size: 0.8rem;
}

.side-navbar.shrink ul li a {
  font-size: 0.7rem;
  padding-left: 5px;
  padding-right: 5px;
}

.side-navbar.shrink ul li a[data-toggle="collapse"]::before {
  content: "\f107";
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  position: absolute;
  top: auto;
  right: 50%;
  bottom: 0;
  left: auto;
}

.side-navbar.shrink
  ul
  li
  a[data-toggle="collapse"][aria-expanded="true"]::before {
  content: "\f106";
}

.side-navbar.shrink ul li ul a {
  padding-left: 10px;
  font-size: 0.7rem;
}

.side-navbar.shrink i {
  display: block;
  margin-right: 0;
  font-size: 1rem;
}

.side-navbar.shrink .arrow {
  float: none;
  -webkit-transform: none !important;
  transform: none !important;
}

.side-navbar.shrink .badge {
  display: none;
}

/* SIDE NAVBAR MEDIAQUERIES ------------------------------ */
@media (max-width: 1199px) {
  .page {
    width: 100%;
  }
  .page.active {
    width: 100%;
  }
  .page.active-sm {
    width: calc(100% - 70px);
  }
  .side-navbar {
    left: -70px;
    width: 70px;
    text-align: center;
  }
  .side-navbar.show-sm {
    left: 0 !important;
  }
  .side-navbar .brand-small {
    display: block;
  }
  .side-navbar .sidenav-header-inner {
    display: none;
  }
  .side-navbar .sidenav-heading {
    margin-left: 0;
    font-size: 0.8rem;
  }
  .side-navbar ul li a {
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  .side-navbar ul li a[data-toggle="collapse"]::before {
    content: "\f107";
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
  }
  .side-navbar ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: "\f106";
  }
  .side-navbar ul li ul a {
    padding-left: 10px;
    font-size: 0.7rem;
  }
  .side-navbar i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
  }
  .side-navbar .arrow {
    float: none;
    -webkit-transform: none !important;
    transform: none !important;
  }
  .side-navbar .badge {
    display: none;
  }
}

/* Malihu Custom Scroll */
.mCSB_scrollTools {
  width: 4px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0 !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #6c1a74;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
  background-color: #6c1a74;
}

/*
* ==========================================================
*      DASHBOARD COUNTS
* ==========================================================
*/
.dashboard-counts {
  color: #333;
}

.dashboard-counts .count-title span {
  font-size: 0.8em;
  color: #aaa;
  display: block;
}

.dashboard-counts .count-title i {
  font-size: 1.5em;
  color: #6c1a74;
}

.dashboard-counts .icon {
  margin-right: 10px;
}

.dashboard-counts strong {
  font-size: 0.9em;
  font-weight: 500;
  color: #555;
  display: block;
}

.dashboard-counts .count-number {
  font-size: 2.2em;
  font-weight: 300;
}

/* DASHBOARD COUNTS MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .dashboard-counts div[class*="col-"] {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .dashboard-counts strong {
    font-size: 1em;
  }
  .dashboard-counts span {
    font-size: 0.9em;
  }
  .dashboard-counts .count-number {
    font-size: 3em;
  }
}

/*
* ==========================================================
*     DASHBOARD HEADER
* ==========================================================
*/
.dashboard-header {
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.dashboard-header .check-lists {
  margin-top: 20px;
}

.dashboard-header .check-lists label {
  font-size: 0.75em;
}

.dashboard-header .pie-chart {
  position: relative;
  width: 300px;
  height: 300px;
  max-width: 100%;
  margin: 0 auto;
}

.dashboard-header p {
  font-size: 0.75rem;
  font-weight: 400;
  color: #888;
}

.dashboard-header li {
  margin-bottom: 10px;
}

/* DASHBOARD HEADER MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .dashboard-header {
    background: none;
    border: none;
    padding: 0;
  }
  .dashboard-header div[class*="col-"] {
    margin-bottom: 30px;
  }
  .dashboard-header div[class*="col-"]:last-of-type {
    margin-bottom: 0;
  }
  .dashboard-header .card {
    background: #fff;
    padding: 30px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
}

@media (min-width: 768px) {
  .dashboard-header p {
    font-size: 0.9em;
  }
  .dashboard-header h2 {
    font-size: 1.3rem;
  }
  .dashboard-header .check-lists label {
    font-size: 0.85em;
  }
}

@media (min-width: 992px) {
  .dashboard-header {
    margin-bottom: 30px;
  }
  .dashboard-header .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
  }
}

/*
* ==========================================================
*      STATISTICS
* ==========================================================
*/
.statistics {
  /* User Activity ------------- */
}

.statistics div[class*="col-"] .card {
  padding: 20px;
  margin-bottom: 0;
  height: 100%;
}

.statistics h2 {
  margin-bottom: 20px;
}

.statistics p {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
}

.statistics .income .icon {
  font-size: 3.5em;
}

.statistics .income .number {
  font-size: 2.5em;
  color: #555;
}

.statistics .income strong {
  font-size: 1.5em;
}

.statistics .data-usage canvas {
  width: 100%;
  max-width: 200px;
  border: none;
}

.statistics .data-usage strong {
  font-size: 1.5em;
  display: block;
}

.statistics .data-usage small {
  font-size: 0.85em;
  font-weight: 300;
  color: #aaa;
}

.statistics .data-usage span {
  font-size: 0.95em;
  color: #aaa;
  display: block;
}

.statistics .data-usage p {
  margin-top: 10px;
  margin-bottom: 0;
}

.statistics .user-activity .number {
  font-size: 2.5em;
  font-weight: 300;
  color: #555;
}

.statistics .user-activity .progress {
  border-radius: 0;
  background: #f5f5f5;
}

.statistics .user-activity .progress .progress-bar.bg-primary {
  background: #6c1a74 !important;
}

.statistics .user-activity .page-statistics {
  margin-top: 20px;
}

.statistics .user-activity .page-statistics strong {
  display: block;
  text-transform: uppercase;
  color: #6c1a74;
  font-size: 2em;
}

/* STATISTICS  MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .statistics div[class*="col-"] .card {
    height: auto;
    margin-bottom: 30px;
  }
  .statistics div[class*="col-"]:last-of-type .card {
    margin-bottom: 0;
  }
  .statistics .data-usage {
    text-align: center;
  }
  .statistics .data-usage div[class*="col-"]:first-of-type {
    padding-bottom: 20px;
  }
  .statistics .data-usage .gmpc {
    padding-bottom: 20px !important;
  }
  p {
    font-size: 0.75em;
  }
}

@media (min-width: 768px) {
  .statistics h2 {
    font-size: 1.3rem;
  }
}

/*
* ==========================================================
*      UPDATES WIDGETS
* ==========================================================
*/
.updates .left-col {
  padding-right: 20px;
}

.updates .icon {
  margin-right: 10px;
}

.updates li {
  border-bottom: 1px solid #f3f3f3;
  padding: 0.75rem 1.25rem;
}

.updates .update-date {
  font-size: 1.3em;
  font-weight: 700;
  color: #bbb;
  display: block;
}

.updates span {
  font-size: 0.7em;
  font-weight: 300;
  color: #aaa;
  line-height: 0.5em;
  display: block;
}

.updates strong {
  font-weight: 400;
  color: #555;
  font-size: 0.9em;
}

.updates small {
  font-size: 0.75em;
}

.updates h2 {
  margin-bottom: 0;
}

.updates p {
  font-size: 0.9em;
  color: #999;
  margin-bottom: 5px;
}

/* UPDATES MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .updates h2 {
    font-size: 1.05rem;
  }
  .updates strong {
    font-size: 1em;
    font-weight: 400;
  }
  .updates small {
    font-size: 0.85em;
  }
  .updates .full-date {
    font-size: 0.85em;
  }
}

/*
* ==========================================================
*      DAILY FEEDS AND ACTIVITES WIDGETS
* ==========================================================
*/
/*  Daily Feeds ------------------- */
.daily-feeds a.feed-profile {
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.daily-feeds a.feed-profile img {
  width: 100%;
  height: 100%;
}

.daily-feeds .badge {
  margin-right: 10px;
}

.daily-feeds strong {
  display: inline-block;
  margin-right: 5px;
}

.daily-feeds small {
  color: #999;
  display: block;
  margin-bottom: 5px;
}

.daily-feeds .message-card {
  padding: 10px;
  background: #fafafa;
  margin-left: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.daily-feeds .full-date {
  line-height: 0.7em;
  margin-bottom: 10px;
}

.daily-feeds .CTAs a {
  margin-right: 5px;
}

.daily-feeds .CTAs a i {
  margin-right: 5px;
}

.daily-feeds .CTAs a:last-of-type {
  margin-right: 0;
}

.daily-feeds a.btn {
  color: #fff;
}

/* Activities  ------------------- */
.activities li {
  padding: 0;
}

.activities .date-holder {
  padding-right: 0;
}

.activities .date-holder .date {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.activities .date-holder .date span {
  font-size: 0.8em;
}

.activities .date-holder .icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #f5f5f5;
  text-align: center;
  margin: 0;
  display: inline-block;
}

.activities .date-holder span {
  display: block;
  margin: 10px;
}

.activities .content {
  border-left: 1px solid #eee;
  border-bottom: 20px;
  padding: 10px 20px;
}

.activities .content strong {
  display: block;
  margin-right: 5px;
}

/* UPDATES WIDGET MEDIAQUERIES ------------------------- */
@media (min-width: 768px) {
  .updates strong {
    font-size: 1em;
  }
  .updates small {
    font-size: 0.8em;
  }
}

/*
* ==========================================================
*      PROGRESS CIRCLE
* ==========================================================
*/
.gmpc {
  padding: 0 !important;
  width: 100% !important;
  height: auto !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #6c1a74 !important;
  border-right: 1px solid #eee;
}

@media (max-width: 575px) {
  .gmpc {
    border-right: none;
    border-bottom: 1px solid #eee;
  }
}

/*
* ==========================================================
*       MAIN FOOTER
* ==========================================================
*/
footer.main-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #222;
  padding: 10px 0;
}

footer.main-footer p {
  font-size: 0.7em;
  color: #777;
  margin: 0;
}

/* MAIN FOOTER MEDIAQUERIES ------------------------- */
@media (max-width: 575px) {
  footer.main-footer div[class*="col-"] {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  footer.main-footer p {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*       UTILITIES
* ==========================================================
*/
.bg-cover {
  background-size: cover;
}

/*
* ==========================================================
*     POLAR CHART
* ==========================================================
*/
.polar-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/*
* ==========================================================
*     PIE CHART
* ==========================================================
*/
.pie-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/*
* ==========================================================
*     RADAR CHART
* ==========================================================
*/
.radar-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/* CHARTS MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  section.charts h2 {
    font-size: 1rem;
  }
}

/*
* ==========================================================
*     FORMS ELEMENTS
* ==========================================================
*/
label {
  font-size: 0.9rem;
}

.form-control {
  height: calc(2.4rem + 2px);
  padding: 0.45rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  border-radius: 0;
}

.form-control::-moz-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control:-ms-input-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

textarea.form-control {
  height: auto;
}

select.form-control {
  font-weight: 300;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

select.form-control option {
  color: #999;
  font-weight: 300;
}

.custom-select {
  height: calc(2.4rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.custom-select:focus {
  border-color: #88dda2;
  outline: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.form-group {
  margin-bottom: 10px;
}

.form-group div[class*="col-"] {
  margin-bottom: 10px;
}

.input-group-text {
  color: #868e96;
  background: #fff;
  border-radius: 0;
}

.form-group-material {
  position: relative;
  margin-bottom: 30px;
}
.register-from-outer .form-group-material {
  margin-bottom: 10px;
}

input.input-material {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  color: #868e96;
  font-weight: 300;
  background: transparent;
  border-radius: 0;
}

input.input-material.is-valid {
  border-color: #6c1a74 !important;
}

input.input-material.is-invalid {
  border-color: #dc3545 !important;
}

input.input-material:focus {
  border-color: #6c1a74;
  outline: none;
}

input.input-material ~ label {
  color: #aaa;
  position: absolute;
  top: 14px;
  left: 0;
  cursor: text;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 300;
}

input.input-material ~ label.active {
  font-size: 0.8rem;
  top: -10px;
  color: #6c1a74;
}

input.input-material.is-invalid ~ label {
  color: #dc3545;
}

input.form-control-custom {
  visibility: hidden;
  width: 1px;
}

input.form-control-custom:checked + label::before {
  background: #6c1a74 !important;
}

input.form-control-custom:checked + label::after {
  opacity: 1 !important;
}

input.form-control-custom.radio-custom + label::before {
  border-radius: 50%;
}

input.form-control-custom.radio-custom + label::after {
  content: "";
  width: 6px;
  height: 6px;
  line-height: 6px;
  background: #fff;
  border-radius: 50%;
  top: 7px;
  left: 5px;
}

input.form-control-custom[disabled] + label {
  cursor: not-allowed;
}

input.form-control-custom[disabled] + label::before,
input.form-control-custom[disabled] + label::after {
  border: 2px solid #aaa;
  cursor: not-allowed;
}

input.form-control-custom[checked] + label::before,
input.form-control-custom[checked] + label::after {
  border: none;
}

input.form-control-custom + label {
  font-size: 0.85rem;
  margin-bottom: 0;
  margin-left: 0;
  color: #999;
  padding-left: 25px;
  padding-top: 2px;
  position: relative;
}

input.form-control-custom + label span {
  margin-left: 20px;
}

input.form-control-custom + label::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: #ddd;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 2px;
  left: 0;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
}

input.form-control-custom + label::after {
  content: "\f00c";
  display: block;
  font-family: "FontAwesome";
  font-size: 8px;
  color: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0;
  cursor: pointer;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #868e96 !important;
  color: #868e96 !important;
}
button {
  cursor: pointer;
}

/*
* ==========================================================
*     FORMS GENERAL
* ==========================================================
*/
.forms .line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #eee;
  margin: 20px 0 30px;
}

.forms p {
  font-size: 0.8rem;
  color: #868e96;
}

.forms small {
  color: #868e96;
}

/* FORMS MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  .forms h2 {
    font-size: 1rem;
  }
  .forms input.form-control {
    border-radius: 0;
  }
  .forms input.form-control::-moz-placeholder {
    font-size: 0.85em;
  }
  .forms input.form-control::-webkit-input-placeholder {
    font-size: 0.85em;
  }
  .forms input.form-control:-ms-input-placeholder {
    font-size: 0.85em;
  }
  .forms p {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/
.vimeo-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
  background-image: url("../img/login_bg.png");
}
.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-page,
.register-page {
  width: 100%;
  background: #f5f5f5;
  min-height: auto;
  padding-bottom: 0;
}

.login-page .form-outer,
.register-page .form-outer {
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.login-page .copyrights,
.register-page .copyrights {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}

.login-page .copyrights p,
.register-page .copyrights p {
  font-size: 0.6em;
  font-weight: 300;
  margin-bottom: 10px;
}

.login-page .form-inner,
.register-page .form-inner {
  padding: 40px 30px;
  background: #fff;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
}
.register-page .form-inner {
  padding: 15px 30px;
}

.login-page .logo,
.register-page .logo {
  font-size: 2em;
  font-weight: 500;
}
.logo img {
  height: 50px;
}

.login-page .logo span,
.register-page .logo span {
  color: #ccc;
}

.login-page p,
.register-page p {
  font-size: 0.8em;
  font-weight: 300;
  color: #aaa;
  margin-bottom: 0;
}

.login-page form {
  margin: 2rem auto 1rem;
  max-width: 420px;
}
.register-page form {
  margin: 1rem auto 1rem;
  max-width: 420px;
}

.login-page a.forgot-pass,
.login-page a.signup,
.register-page a.forgot-pass,
.register-page a.signup {
  font-size: 0.65em;
}

.login-page a.forgot-pass:hover,
.login-page a.forgot-pass:focus,
.login-page a.signup:hover,
.login-page a.signup:focus,
.register-page a.forgot-pass:hover,
.register-page a.forgot-pass:focus,
.register-page a.signup:hover,
.register-page a.signup:focus {
  color: #5e167f;
  text-decoration: none;
}

.login-page a.forgot-pass,
.register-page a.forgot-pass {
  display: block;
}

.login-page a.create-account,
.register-page a.create-account {
  border-radius: 50px;
  font-size: 0.75em;
  margin-top: 10px;
  color: #999;
  text-transform: uppercase;
}

.login-page .terms-conditions,
.register-page .terms-conditions {
  margin-top: 20px;
}

.login-page small,
.register-page small {
  font-size: 0.65em;
  color: #aaa;
  line-height: 0.9em;
}
span#login-wrong-credentials-error {
  /* margin-top: -28px; */
  margin-bottom: 10px;
  text-align: center;
}
/* LOGIN MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  .login-page p,
  .register-page p {
    font-size: 0.9em;
  }
  .login-page form label.label-custom,
  .register-page form label.label-custom {
    font-size: 0.9em;
  }
  .login-page form small,
  .register-page form small {
    font-size: 0.8em;
  }
  .login-page .terms-conditions label,
  .register-page .terms-conditions label {
    font-size: 0.9em;
  }
  .login-page a.forgot-pass,
  .login-page a.signup,
  .register-page a.forgot-pass,
  .register-page a.signup {
    font-size: 0.8em;
  }
  .login-page small,
  .register-page small {
    font-size: 0.8em;
  }
  .login-page .copyrights p,
  .register-page .copyrights p {
    font-size: 0.85em;
    font-weight: 400;
  }
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  right: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  right: 0px;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* ========================================= 
   THEMING OF BOOTSTRAP COMPONENTS           
   ========================================= */
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.45rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not([disabled]):not(.disabled):active,
.btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

.btn-primary {
  color: color-yiq(#6c1a74);
  background-color: #6c1a74;
  border-color: #6c1a74;
}

.btn-primary:hover {
  color: color-yiq(#5e167f);
  background-color: #5e167f;
  border-color: #5e167f;
}

.btn-primary:focus,
.btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #6c1a74;
  border-color: #6c1a74;
}

.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: color-yiq(#5e167f);
  background-color: #5e167f;
  border-color: #5e167f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 26, 116, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(108, 26, 116, 0.5);
}

.btn-secondary {
  color: color-yiq(#868e96);
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: color-yiq(#727b84);
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus,
.btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: color-yiq(#6c757d);
  background-color: #6c757d;
  border-color: #666e76;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-success {
  color: color-yiq(#28a745);
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: color-yiq(#218838);
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: color-yiq(#1e7e34);
  background-color: #1e7e34;
  border-color: #1c7430;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: color-yiq(#17a2b8);
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: color-yiq(#138496);
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: color-yiq(#117a8b);
  background-color: #117a8b;
  border-color: #10707f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: color-yiq(#ffc107);
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: color-yiq(#e0a800);
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: color-yiq(#d39e00);
  background-color: #d39e00;
  border-color: #c69500;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: color-yiq(#dc3545);
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: color-yiq(#c82333);
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: color-yiq(#bd2130);
  background-color: #bd2130;
  border-color: #b21f2d;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: color-yiq(#f8f9fa);
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: color-yiq(#e2e6ea);
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: color-yiq(#dae0e5);
  background-color: #dae0e5;
  border-color: #d3d9df;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: color-yiq(#343a40);
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: color-yiq(#23272b);
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: color-yiq(#1d2124);
  background-color: #1d2124;
  border-color: #171a1d;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-default {
  color: color-yiq(#ced4da);
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-default:hover {
  color: color-yiq(#b8c1ca);
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}

.btn-default:focus,
.btn-default.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-default.disabled,
.btn-default:disabled {
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: color-yiq(#b1bbc4);
  background-color: #b1bbc4;
  border-color: #aab4bf;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-primary {
  color: #6c1a74;
  background-color: transparent;
  background-image: none;
  border-color: #6c1a74;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #6c1a74;
  border-color: #6c1a74;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #6c1a74;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6c1a74;
  border-color: #6c1a74;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not([disabled]):not(.disabled):active,
.btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not([disabled]):not(.disabled):active,
.btn-outline-info:not([disabled]):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not([disabled]):not(.disabled):active,
.btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not([disabled]):not(.disabled):active,
.btn-outline-danger:not([disabled]):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not([disabled]):not(.disabled):active,
.btn-outline-light:not([disabled]):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not([disabled]):not(.disabled):active,
.btn-outline-dark:not([disabled]):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

/*
 * 3. TYPE  
 */
body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

a {
  color: #6c1a74;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #1b102c;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 1.5rem;
}

h2,
.h2 {
  font-size: 1.3rem;
}

h3,
.h3 {
  font-size: 1.2rem;
}

h4,
.h4 {
  font-size: 1.1rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border-left: 5px solid #6c1a74;
}

.blockquote-footer {
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #6c1a74 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #288b46 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #6c1a74;
  border-color: #6c1a74;
}

.page-item.disabled .page-link {
  color: #868e96;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #6c1a74;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus,
.page-link:hover {
  color: #22773c;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
  outline: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. DROPDOWNS
*/
.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  background-color: #6c1a74;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #868e96;
}

.dropdown-header {
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  color: #868e96;
}

/*
* 6. UTILITIES
*/
.bg-primary {
  background-color: #6c1a74 !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #288b46 !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #6c1a74 !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #6c1a74 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #288b46 !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.badge-primary {
  color: color-yiq(#6c1a74);
  background-color: #6c1a74;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: color-yiq(#6c1a74);
  text-decoration: none;
  background-color: #288b46;
}

.badge-secondary {
  color: color-yiq(#868e96);
  background-color: #868e96;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: color-yiq(#868e96);
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: color-yiq(#28a745);
  background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: color-yiq(#28a745);
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: color-yiq(#17a2b8);
  background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: color-yiq(#17a2b8);
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: color-yiq(#ffc107);
  background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: color-yiq(#ffc107);
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: color-yiq(#dc3545);
  background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: color-yiq(#dc3545);
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: color-yiq(#f8f9fa);
  background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: color-yiq(#f8f9fa);
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: color-yiq(#343a40);
  background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: color-yiq(#343a40);
  text-decoration: none;
  background-color: #1d2124;
}

/*
* 7.CODE
*/
code {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #e83e8c;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #6c1a74;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 0 solid #eee;
  border-radius: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: 0;
}

.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.intl-tel-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  padding: 0 0 0 8px;
}

.intl-tel-input .selected-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.intl-tel-input .selected-flag .iti-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.intl-tel-input .selected-flag .iti-arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
}

.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}

.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box,
.intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type="text"],
.intl-tel-input.separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  right: auto;
  left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag,
.intl-tel-input.separate-dial-code .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover,
.intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown
  input[disabled]
  + .flag-container:hover
  .selected-flag,
.intl-tel-input.allow-dropdown
  input[readonly]
  + .flag-container:hover
  .selected-flag {
  background-color: transparent;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table;
}

.intl-tel-input.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-2 input[type="tel"] {
  padding-left: 66px;
}

.intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 60px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type="tel"] {
  padding-left: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-3 input[type="tel"] {
  padding-left: 74px;
}

.intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 68px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type="tel"] {
  padding-left: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-4 input[type="tel"] {
  padding-left: 82px;
}

.intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 76px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="tel"] {
  padding-left: 92px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type="text"],
.intl-tel-input.separate-dial-code.iti-sdc-5 input[type="tel"] {
  padding-left: 90px;
}

.intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 84px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type="text"],
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type="tel"] {
  padding-left: 100px;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}

.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0px;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.9/img/flags.png");
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.9/img/flags@2x.png");
  }
}

.iti-flag.np {
  background-color: transparent;
}

.hide {
  display: none;
}

pre {
  margin: 0 !important;
  display: inline-block;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  background: none;
}

.payment_success .box,
.payment_success .loader_img {
  z-index: 2;
}

@media (max-width: 767px) {
  .header_content .btn_wrap .btn img {
    height: 30px;
  }
  a.inner_btn {
    font: 13px/1 "PromptRegular";
  }
  .header_content .btn_wrap {
    margin-left: 0;
  }
  .btn_wrap li:before,
  .header_content .btn_wrap .btn:before {
    display: none;
  }
  .ui.column.grid > [class*="ten wide"].column,
  .ui.grid > .column.row > [class*="ten wide"].column,
  .ui.grid > .row > [class*="ten wide"].column,
  .ui.grid > [class*="ten wide"].column {
    width: 100%;
  }
  .ui.grid > [class*="six wide"].column {
    width: 100% !important;
  }
  .card-col img {
    height: 160px;
    margin-top: 20px;
  }
  .top-banner-head {
    display: inline !important;
  }
  .left-top-col,
  .right-top-col {
    width: 100%;
  }
  .luggage_tag {
    display: none;
  }
  .timer-container {
    top: 100px;
    width: 95%;
  }
  .header_content .btn_wrap .btn {
    min-width: 100%;
    margin-bottom: 5px;
  }
  .header_content h1 {
    margin-top: 80px;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 10px;
    font-size: 20px;
  }
  .header_content h2 {
    font-size: 17px;
    margin-left: 10px;
  }
  .right-col-image {
    width: 100%;
  }
  .right-top-col img {
    height: 218px;
  }
  .travolier_bg .header_content span {
    font-size: 2em !important;
  }
  .app_download_section .content > div h4 {
    font-size: 11px !important;
  }
  .app_download_section.hotelier_in_numbers .content > div {
    padding: 50px 30px !important;
  }
  .private_bg .header_content span {
    font-size: 2em !important;
  }
  .left_col {
    padding: 0;
  }
  .left_col p,
  .right_col p {
    font-size: 1em;
  }
  .right_col,
  .left_col {
    margin-bottom: 50px;
  }
  .link_btn {
    background: #1d1350;
    color: #fff;
    padding: 0px;
    top: 10px;
    border-radius: 6px;
    margin-right: -15px;
  }
}

.media-quote-div {
  height: 100%;
  margin: 0 10%;
  padding-top: 40px;
  padding-bottom: 60px;
  text-align: center;
}
.media-quote-div h3 {
  margin-bottom: 10px;
  font-size: 25px;
}
.speech-icon {
  margin-bottom: 0;
}
.quote-text {
  margin-right: 0;
  margin-left: 0;
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
}
.media-logo {
  margin-top: 20px;
}
.travolier_logo_brand {
  padding: 25px !important;
}
.ferdi {
  background: #fff;
}
.main_row {
  padding: 60px;
}
.main_row h2 {
  color: #6a0074;
  font-size: 34px;
}
.main_row p {
  margin-bottom: 20px;
  font-size: 16px;
}
.main_row ul {
  list-style: circle;
  margin-left: 15px;
}
.main_row ul li {
  margin-bottom: 10px;
}
/* input {
  display: block;
  margin: 0 auto 7px;
  padding: 10px 20px ;
  width: 100%;
  height: 100%;
  background: #fff !important;
  border: 1px solid #eee !important;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  outline: none;
} */

.logo img {
  height: 40px;
}
.logo img.logo_2 {
  height: 43px;
  margin-top: -2px;
}
.index2 header.sticky {
  height: 60px;
}
.sticky .user_details_col {
  display: none;
}
.right_nav li a {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0.15);
  margin-left: 5px;
  padding: 5px 20px;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.right_nav li a:hover {
  background: #1b0e49;
}
.right_nav li a i.mdi {
  font-size: 22px;
  margin-right: 5px;
}
.user_details_col {
  position: absolute;
  top: -70px;
  z-index: 1;
  color: #fff;
}
span.short_name {
  height: 50px;
  width: 50px;
  display: flex;
  background: #fff;
  border-radius: 50%;
  color: #6c1a74;
  text-align: center;
  /* line-height: 50px; */
  font-size: 26px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
}
span.short_name img {
  height: 50px;
  width: 50px;
  /* margin-top: -2px; */
  /* margin-left: -1px; */
  border: 2px solid #fff;
  border-radius: 50%;
}
.user_details_col .mdi {
  font-size: 18px;
  cursor: pointer;
  display: none;
}

.has-left-border {
  border-left: 4px solid #6c1a74;
}

.btn {
  position: relative;
}
.action_loader {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;

  background: #6c1a74;
}

.email_status_main {
  position: relative;
}
.email_status_main .text-success {
  font-size: 28px;
  position: absolute;
  top: -10px;
  right: 0;
}

.c-w-d {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
  font-size: 35px !important;
}

.media-quote-div.token_sale_quote {
  height: 100%;
  margin: 0 10%;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}
.speech-icon {
  margin-bottom: 25px;
}
.quote-text {
  margin-right: 0;
  margin-left: 0;
  font-size: 24px;
  line-height: 38px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1px;
  color: #333333;
}
.coin_glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.coin_glow img {
  height: 220px;
  border: none;
}

.we_are_misson_h3 {
  text-align: center;
  max-width: 80%;
  margin: 35px auto;
  line-height: 25px;
  font-size: 20px;
  font-weight: 700 !important;
}
.media-logo {
  margin-top: 20px;
}
.token_sale_wrapper.vimeo-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 22vh;
  pointer-events: initial;
  overflow: hidden;
  background-image: url("../../img/token_sale_banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.token_sale_wrapper.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video_content_banner {
  display: flex;

  align-items: center;
  position: absolute;
  padding: 0 150px;
}
.vimeo-wrapper h5 {
  font-size: 1.5rem;
  color: #fff;
  max-width: 70%;
}

.token_sale_user_details {
  position: absolute;
  top: 110px;
  z-index: 99;
  color: #fff;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.htlc_balance_top {
  min-width: 52%;
}
.htlc_balance_top .col-md-4,
.htlc_balance_top .col-md-6 {
  display: flex;
  align-items: center;
  border-left: 4px solid #fff;
  color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 30.333333%;
  margin-left: 10px;
  background: #6c1a74;
  opacity: 0.6;
}
.htlc_balance_top .col-md-6 {
  max-width: 47%;
}
.htlc_balance_top .col-md-4:first-child,
.htlc_balance_top .col-md-6:first-child {
  opacity: 1;
  padding: 10px 6px;
}
.htlc_balance_top .col-md-4:hover,
.htlc_balance_top .col-md-6:hover {
  opacity: 1;
}
.htlc_balance_top .col-md-4 .name,
.htlc_balance_top .col-md-6 .name {
  font-size: 11px;
}
.htlc_balance_top .col-md-4:first-child .name,
.htlc_balance_top .col-md-6:first-child .name {
  font-size: 14px;
}
.htlc_balance_top .col-md-4.airdrop_col .name {
  font-size: 12px;
}
.airdrop_row .count-number {
  font-size: 15px;
  font-weight: 800;
}
.token_balance_list .list-group-item {
  background: #1b0e49;
  color: #fff;
  margin-bottom: 3px;
  border: none;
  opacity: 0.7;
}

.token_balance_list:first-child .list-group-item:first-child {
  opacity: 1;
}

.fixed-header .token_sale_user_details {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: #1d1350;
  padding: 10px 3rem;
  /* transition: all .3s ease-in-out; */
}
.fixed-header .inner_header {
  display: none;
}
/* .book_now_token_sale{
  position: absolute;
    z-index: 9;
    right: 20%;
    bottom: -120px;
} */
/* .fixed-header .book_now_token_sale{
  display: none;
} */
.fixed-header.inner_main .page {
  top: 135px;
}
.mobile_navbar {
  display: none;
}
.search_box_group {
  max-width: 600px;
  margin: 0 auto;
}
.search_box_group .form-control {
  border-radius: 40px;
  height: 50px;
}
.search_box_group .input-group-append {
}
.search_box_group .btn {
  border-radius: 50%;
  width: 50px;
}
@media (max-width: 767px) {
  .slide0 .slide_content h3 {
    font-size: 15px;
  }
  .privacy_inner .content {
    padding: 32px 15px;
  }
  .airdrop_main_i .token_sale_user_details {
    top: 80px;
  }
  .airdrop_main_i .htlc_balance_top .col-md-4 {
    max-width: 48%;
    margin-left: 3.5px;
    margin-right: 2.5px;
  }
  .airdrop_main_i .htlc_balance_top .col-md-4:first-child {
    max-width: 100%;
    margin-bottom: 8px;
    height: 44px;
  }
  .statistics div[class*="col-"] .card {
    padding: 0;
  }
  .not_mobile_menu {
    display: none;
  }
  .mobile_navbar {
    text-align: center;
    display: inherit;
  }
  .mobile_navbar img {
    height: 38px;
  }
  .token_sale_user_details {
    top: 95px;
    padding: 0 20px;
  }
  .fixed-header .token_sale_user_details {
    padding: 10px 20px;
  }
  .token_sale_user_details .media {
    display: none;
  }
  .profile_main_i .token_sale_user_details .media,
  .history_main_i .token_sale_user_details .media,
  .withdraw_main_i .token_sale_user_details .media {
    word-break: break-all;
    display: inherit;
  }
  .token_sale_user_details .row {
    display: flex !important;
  }
  .htlc_balance_top .col-md-4 .name {
    font-size: 10px;
  }

  .htlc_balance_top .col-md-4:first-child .name {
    font-size: 10px;
  }
  .video_content_banner {
    display: initial;
  }
  .video_content_banner {
    padding: 0 15px;
  }
  .vimeo-wrapper h5 {
    font-size: 1rem;
    max-width: 100%;
  }
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  #menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383f;
  }
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menu {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: -26px;
    box-shadow: 0 0 10px #85888c;
    margin: -50px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    background-color: #f5f6fa;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    transition-delay: 2s;
  }
  #menu li a {
    font-size: 30px;
  }
  #menuToggle input:checked ~ ul {
    transform: none;
  }
  .menubar-collapsed {
    overflow-y: hidden;
  }
  .menubar-collapsed .token_sale_user_details {
    z-index: 0;
  }
}

.zsiq_theme1 div.zsiq_cnt {
  display: none;
}

.qoute_wrap {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
.qoute_wrap .forbs_logo {
  height: 35px;
  margin-top: 20px;
}
.btn_section_sec .wp_btn_wrap {
  max-width: 42%;
  display: flex;
  margin: 0 auto;
  text-align: center;
}
.winner_wrap {
  width: 100%;
  margin: 40px 0 60px;
}
.banner-container {
  max-width: 100%;
}

.winner_wrap .winning_icon {
  height: 120px;
}

.winning_banner {
  /* Mask: */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #6a0074cc),
    color-stop(85%, #6a007459)
  );
  background-image: linear-gradient(-180deg, #6a0074cc 50%, #6a007459 85%);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(49, 75, 78, 0.34);
  box-shadow: 0px 0px 10px 0px rgba(49, 75, 78, 0.34);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.banner__logo {
  bottom: 0;
  position: absolute;
  width: 20%;
  height: 100%;
  z-index: 5;
  background-image: url("../img/winner.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 29%;
}

.banner__logo .banner__text {
  display: none;
}

.banner__logo .banner__irregular-section {
  position: absolute;
  top: -33%;
  left: -10%;
  width: 120%;
  height: 150%;
  -webkit-transform: rotate(8deg);
  transform: rotate(8deg);
}

.banner__intro {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 70%;
}
.banner__intro .banner__text {
  color: #fff;
  font-size: 20px;
  padding: 15px 0 0 50px;
  text-align: center;
}
.banner__intro .banner__irregular-section {
  background-color: #1d1350;
  position: absolute;
  top: -30%;
  width: 150%;
  height: 200%;
  -webkit-transform: rotate(3deg);
  transform: rotate(3deg);
}

.banner__content {
  padding: 90px 0 0 32%;
}

.banner__text {
  position: relative;
  z-index: 5;
}

.banner__irregular-section {
  z-index: 1;
}

.banner__cta {
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 10;
  margin-top: -1em;
}

.banner__btn {
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  background-color: #6a0074;
  border: 2px solid #fff;
  padding: 0.75em 1em;
  text-transform: uppercase;
  text-decoration: none;
}

.banner-slides {
  position: relative;
  height: 3.5em;
}

.banner-slides__item {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-animation: banner-anim-first 13s infinite;
  animation: banner-anim-first 13s infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  color: #fff;
}
.banner-slides__item + .banner-slides__item {
  left: 100%;
  -webkit-animation-name: banner-anim;
  animation-name: banner-anim;
}
.banner-slides__item + .banner-slides__item + .banner-slides__item {
  -webkit-animation-delay: 4.3s;
  animation-delay: 4.3s;
}

@-webkit-keyframes banner-anim-first {
  0%,
  15%,
  85%,
  100% {
    left: 0;
    opacity: 1;
  }
  18%,
  82% {
    left: 100%;
    opacity: 0;
  }
}

@keyframes banner-anim-first {
  0%,
  15%,
  85%,
  100% {
    left: 0;
    opacity: 1;
  }
  18%,
  82% {
    left: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes banner-anim {
  0%,
  15%,
  52%,
  100% {
    left: 100%;
  }
  18%,
  48% {
    left: 0;
  }
}
@keyframes banner-anim {
  0%,
  15%,
  52%,
  100% {
    left: 100%;
  }
  18%,
  48% {
    left: 0;
  }
}

ul.one_travel_ul li {
  font-size: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  color: #333;
}
ul.one_travel_ul li i.mdi {
  color: #333;
  font-weight: 800;
  font-size: 13px;
  margin-right: 7px;
  line-height: 0;
  margin-bottom: 5px;
}

.boom_wrap .lead {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
}

.six.wide.column.card-col {
  align-items: center;
  display: flex;
  justify-content: start;
}
.one_text_col {
  display: flex;
  align-items: center;
  justify-content: center;
}
h2.one_text {
  color: #333;
  font-size: 85px;
  font-weight: 700 !important;
  font-family: "Prompt", sans-serif !important;
  letter-spacing: -2px;
}
.c_img_content {
  margin-bottom: 50px;
}
.c_img_content img {
  height: 85px;
  width: 115px;
  object-fit: contain;
  margin-left: -21px;
}
.c_img_content ul li {
  font-size: 16px;
}
.slide_list {
  text-align: center;
}
.slide_list img {
  height: 50px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
  display: inline-block;
}
.graph_img_xx {
  height: 500px;
  margin-top: -73px;
}

.mm_cc_ccc ul li {
  margin-bottom: 10px;
  padding-left: 20px;
}
.ss_row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto 30px;
}
.ss_row .slide_list {
  margin: 0;
  width: 33.333%;
}
.ss_row .slide_list h5 {
  color: #fff;
}
.ss_ss_icons .slide_list {
  text-align: center;
  width: 50%;

  margin-bottom: 45px;
}
.col-md-6.ss_ss_icons h5 {
  color: #fff;
  line-height: 20px;
}
.player .vp-title .vp-title-header {
  display: none !important;
}
.col-md-6.dtmi_logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.col-md-6.dtmi_logo h2 {
  color: #333333;
  font-weight: 700 !important;
}
section.graph_section.graphtwo {
  padding: 60px 0;
  background: #fff;
  margin-bottom: 0;
}
section.graph_section.graphtwo .section_title {
  color: #333;
}
section.graph_section.graphtwo .section_title span {
  text-transform: lowercase;
}
section.graph_section.graphtwo img.gg_one {
  height: 460px;
}
.s_slider_s,
.media_sec {
  margin-bottom: 0;
}

.graphtwo .ashfkajhsd {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: -15px;
}
section.vision_sec {
  padding: 80px 0;
  background: #f3f4f6;
  margin-bottom: 0;
}
.mission_text {
  max-width: 69%;
  margin: 0 auto;
  text-align: center;
}
.mission_text h5 {
  line-height: 30px;
  font-size: 20px;
}
.graphtwo .dtmi_logo img {
  height: 290px;
  width: auto;
  margin-left: -88px;
}
.graphtwo .dtmi_logo .section_title {
  font-size: 16px;
}
.graphtwo .dtmi_logo ul {
  padding: 0 15px;
}
.graphtwo .dtmi_logo ul li {
  list-style-type: disc;
  margin-bottom: 10px;
}
.thankyou_sec p {
  font-size: 16px;
  text-align: justify;
}
p.rana_p {
  display: flex;
  align-items: center;
}
p.rana_p img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
}
.s_slider_s,
.media_sec {
  background: #f9f6fb;
  padding: 60px 0;
}
.footer_banner {
  background: #fff;
  position: relative;
}
.footer_banner img {
  width: 100%;
  object-fit: contain;
  height: 130px;
  margin-bottom: -30px;
  margin-top: 10px;
}
.footer_banner img.shrc_logo {
  margin-top: 0;
  margin-bottom: -20px;
  height: 160px;
}
.register_form_sec {
  text-align: center;
  background: #fff;
  padding: 50px 0 70px;
  margin-bottom: 0;
}
.register_form_sec .form-group-material {
  margin-bottom: 20px;
}
.register_form_sec h2 {
  color: #333333;
  font-size: 28px;
  font-family: "Prompt", sans-serif !important;
  font-weight: 700 !important;
  margin-bottom: 10px;
}
.register_form_sec h5 {
  line-height: 20px;
  color: #333;
}
.register_form_sec input.input-material {
  background-color: #fff;

  border: 1px solid #ced4da;
  padding: 10px 10px;
  border-radius: 4px;

  color: #333333;
}
.how_it_work_sec {
  padding: 100px 0 0;
}
.register_form_sec input.input-material ~ label {
  left: 10px;
}
/* .winner_award_cc_ss {
  background: #f9f6fb;
  padding-top: 40px;
} */
.winner_right_cc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.winner_right_cc ul.one_travel_ul li {
  font-size: 25px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
section.strip_section.thankyou_sec {
  padding: 80px 0 0;
  margin-bottom: 0;
}
#team .section_header_line {
  margin-top: 0;
  position: relative;
  padding-left: 35px;
  z-index: 2;
  color: #7f7f7f;
  margin-bottom: 18px;
  font-size: 18px;
  text-align: left;
}
section.team_banner_sec {
  height: 275px;

  display: flex;
  align-items: center;
  background: #03082b;
}

.col-md-8.ttt_ttt_ttt {
  display: flex;
  align-items: center;
}
.col-md-8.ttt_ttt_ttt h4 {
  font-size: 28px;
  text-align: center;
  line-height: 35px;
  color: #fff;
}
.home_banner_bea .token_sale_wrapper.vimeo-wrapper {
  height: 130px;
  margin-bottom: 60px;
}
.home_banner_bea .video_content_banner {
  width: 100%;
  justify-content: space-around;
  padding: 0;
}
body {
  position: relative;
  height: 100%;
}
.always_on_stick {
  position: relative;
  float: left;
}
.always_on_stick img {
  height: 155px;
  margin-top: 20px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}
.btn--promo {
  background-color: #1a092e;
}

.btn--promo:after {
  content: "with Crypto";
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background-color: #6a0074;
  border-radius: 25px;
  padding: 9px 5px;
  position: absolute;
  margin-top: 65px;
  margin-left: 0;
  min-width: 100px;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1210px;
  }
}

.road_map_sec {
  padding: 100px 35px;
}
.image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image_container img.travolution_logo {
  height: 164px;
  margin-bottom: -20px;
  margin-top: 10px;
}
section.disclaimer_sec {
  padding: 40px 7px;
}
.disclaimer_ul {
  list-style-type: disc;
  margin: 0;
  padding: 0 0 0 15px;
}
.disclaimer_ul li {
  margin-bottom: 10px;
}
section.cryptoBanner {
  padding: 60px 0;
  margin-bottom: 0;
}
.col-md-6.right_padding {
  padding-right: 40px;
}
.col-md-6.left_padding {
  padding-left: 40px;
}
@media (max-width: 767px) {
  .col-md-6.right_padding {
    padding-right: 15px;
  }
  .col-md-6.left_padding {
    padding-left: 15px;
  }
  .c_img_content ul li {
    font-size: 13px;
  }
  .mission_text {
    max-width: 91%;
  }
  .mission_text h5,
  .register_form_sec h5 {
    text-align: justify;
  }
  .form-group.terms-conditions.text-center.mt-0 {
    text-align: justify !important;
  }
  .four_points_sec {
    padding: 50px 0 0;
  }
  .h_t_l_m_sec {
    padding: 30px 0 30px;
  }
  h2.one_text {
    margin: 30px 0 35px;
  }
  .section_title {
    font-size: 16px;
    margin: 30px 0 35px;
    height: auto !important;
  }
  .col-md-6.dtmi_logo h2 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: left;
  }
  .qoute_wrap {
    margin-top: 50px;
  }
  .quote-text {
    margin: 15px 0;
  }
  header .right_topline_buttons {
    display: none;
  }
  .slide_content h3 {
    font-size: 14px;
  }
  .image_container img.travolution_logo {
    height: 164px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .road_map_sec {
    padding: 70px 20px;
  }
  .one_sec {
    padding: 20px 0;
    margin-bottom: 0;
  }
  .popup-youtube_btn {
    top: 70%;
  }

  .mobile_ss {
    margin-top: 20px;
  }
  .mobile_ss.ss_1 {
    margin-left: -53px;
  }
  .always_on_stick {
    display: none;
  }
  .header_logo .logo img {
    height: 30px;
  }
  .logo img.logo_2 {
    height: 33px;
    margin-top: -2px;
  }
  .timer-container {
    top: 148px;
  }
  img.graph_img_xx {
    margin-left: 0;
    width: 100%;
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .col-md-6.ss_ss_icons {
    padding: 50px 15px 15px 15px;
  }
  .ss_row {
    flex-wrap: wrap;
  }
  .ss_row .slide_list {
    margin: 0 3px 15px;
    width: 45%;
  }
  .cryptoBanner img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
  .register_form_sec h2 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .register_form_sec {
    margin-bottom: 0;
    padding-top: 0;
  }
  section.graph_section.graphtwo img.gg_one {
    height: 330px;
    margin-right: -15px;
  }
  .graphtwo .dtmi_logo img {
    height: auto;
    width: 100%;
    margin: 25px 0;
  }
  .register_form_sec {
    margin-bottom: 0;
  }
  .road_main p:before {
    width: 80px;
  }
  section.media_sec {
    padding-left: 15px;
  }
  .slide_content h2 {
    font-size: 18px;
    max-width: 100% !important;
    margin: auto;
    text-align: center !important;
  }
  .slide3 .slide_content h2 {
    max-width: 100%;
    min-width: 100%;
  }
  .t_award h2 {
    font-size: 18px;
  }
  .four_points_sec p {
    text-align: justify;
  }
  .road_main p:nth-child(odd):before {
    left: 100%;
    margin-left: -80px !important;
    top: 20px;
  }
  .road_main p:nth-child(2n):before {
    right: 100%;
    margin-right: -75px !important;
    top: 15px;
  }
  .road_main p:nth-child(2n) {
    padding: 60px 0 0 30px;
  }
  .banner__logo .banner__text {
    display: block;
  }
  .winning_banner {
    height: 235px;
  }
  .banner__logo {
    width: 0%;
  }
  .winner_wrap .winning_icon {
    height: 88px;
    margin-left: -42px;
  }
  .banner__intro .banner__text {
    font-size: 15px;
    padding: 35px 0 15px 6px;
  }
  .banner__intro .banner__irregular-section {
    height: 159%;
  }
  .banner__content {
    padding: 130px 0 0 10%;
  }
  .banner__cta {
    top: auto;
    bottom: 30px;
  }
  .how_it_work_sec {
    padding: 70px 15px 0;
  }
  .token_sale_wrapper.vimeo-wrapper {
    text-align: center;
  }
  section.strip_section.thankyou_sec {
    padding: 35px 0 0;
  }
  .card-col img {
    height: auto;
    margin-top: 20px;
    width: 100%;
    margin: 0;
  }
  section#team {
    padding: 0 15px;
  }
  section.team_banner_sec {
    height: 399px;
  }
  .col-md-8.ttt_ttt_ttt {
    padding-top: 20px;
  }
  .col-md-8.ttt_ttt_ttt h4 {
    font-size: 20px;

    line-height: 26px;
  }
  section#faq {
    padding: 80px 15px 30px;
  }
  .faq .hint {
    word-break: break-word;
  }
  .page.register-page {
    padding-top: 40px;
  }
  .login-page .form-outer,
  .register-page .form-outer {
    top: 0;
    left: 0;
    transform: inherit;
    z-index: 1;
    bottom: 0;
    right: 0;
    height: 100%;
    padding: 0;
  }
  .login-page .form-inner,
  .register-page .form-inner {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }
  .login-page form {
    margin: 100px 0 15px;
  }
}
