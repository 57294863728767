@font-face {
  font-family: 'GeometriaHeavy';
  src: url("../fonts/Geometria-Heavy.eot");
  src: local("Geometria Heavy"), local("Geometria-Heavy"), url("../fonts/Geometria-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Heavy.woff") format("woff"), url("../fonts/Geometria-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaMedium';
  src: url("../fonts/Geometria-Medium.eot");
  src: local("Geometria Medium"), local("Geometria-Medium"), url("../fonts/Geometria-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Medium.woff") format("woff"), url("../fonts/Geometria-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria.eot");
  src: local("Geometria"), url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria.woff") format("woff"), url("../fonts/Geometria.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaBold';
  src: url("../fonts/Geometria-Bold.eot");
  src: local("Geometria Bold"), local("Geometria-Bold"), url("../fonts/Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Bold.woff") format("woff"), url("../fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaExtraBold';
  src: url("../fonts/Geometria-ExtraBold.eot");
  src: local("Geometria ExtraBold"), local("Geometria-ExtraBold"), url("../fonts/Geometria-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-ExtraBold.woff") format("woff"), url("../fonts/Geometria-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaLight';
  src: url("../fonts/Geometria-Light.eot");
  src: local("Geometria Light"), local("Geometria-Light"), url("../fonts/Geometria-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Light.woff") format("woff"), url("../fonts/Geometria-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.button {
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600; }

.dropdown:before, .dropdown:after, .main_block:before, .main_block:after, .currencies > div a:before, .currencies > div a:after, .faq .item p:after, .history_table .operation .active:before, .history_table .operation .active:after, .history_table .date .active:before, .history_table .date .active:after, .reports .operation .active:before, .reports .operation .active:after, .reports .date .active:before, .reports .date .active:after, .success_icon:after, .show_password {
  content: "";
  position: absolute; }


/* input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important; } */

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

form {
  -moz-placeholder-color: #64849f;
  -ms-input-placeholder-color: #64849f; }
  form ::-webkit-input-placeholder {
    color: #64849f; }
  form ::-moz-placeholder {
    color: #64849f; }

.clearfix:after {
  display: block;
  content: "";
  clear: both; }


.dashboard {
  width: 100%;
  position: relative; }

.logo_wrap {
  width: 100%;
  margin: 20px auto;
  padding: 0 10px; }
  .logo_wrap a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .logo_wrap img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 200px; }
  .logo_wrap .logo_mini {
    display: none; }

.nav {
  min-height: 100vh;
  position: fixed;
  width: 15%;
  top: 0;
  left: 0;
  background-color: #000c15;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 18, 33, 0.25)), to(rgba(0, 70, 129, 0.25)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  background-image: linear-gradient(0deg, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  -webkit-box-shadow: inset 0px 0px 60px 0px rgba(0, 68, 125, 0.07);
          box-shadow: inset 0px 0px 60px 0px rgba(0, 68, 125, 0.07);
  overflow: auto;
  height: 100%; }
  .nav ul {
    position: relative;
    list-style-type: none;
    text-align: center; }
    .nav ul li {
      text-align: left;
      border-right: 2px solid transparent;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .nav ul li.user a svg {
        width: 20px;
        height: 20px; }
      .nav ul li a {
        display: block;
        padding: 15px 20px;
        font: 14px/1.2 'GeometriaLight';
        color: #64849f;
        letter-spacing: 1px;
        position: relative; }
        .nav ul li a svg {
          width: 22px;
          height: 22px;
          margin-right: 15px;
          vertical-align: bottom; }
          .nav ul li a svg path, .nav ul li a svg .circle {
            fill: #64849f; }
          .nav ul li a svg .partner {
            stroke: #64849f; }
          .nav ul li a svg .withdraw-1, .nav ul li a svg .withdraw-2, .nav ul li a svg .withdraw-3, .nav ul li a svg .withdraw-4 {
            fill: none;
            stroke: #64849f; }
      .nav ul li:hover, .nav ul li.active {
        background-color: #001425;
        border-right: 2px solid #00be82; }
        .nav ul li:hover a svg path.partner, .nav ul li.active a svg path.partner {
          stroke: #00be82; }
        .nav ul li:hover a, .nav ul li.active a {
          color: #00be82; }
          .nav ul li:hover a svg path, .nav ul li:hover a svg .circle, .nav ul li.active a svg path, .nav ul li.active a svg .circle {
            fill: #00be82; }
          .nav ul li:hover a svg .withdraw-1, .nav ul li:hover a svg .withdraw-2, .nav ul li:hover a svg .withdraw-3, .nav ul li:hover a svg .withdraw-4, .nav ul li.active a svg .withdraw-1, .nav ul li.active a svg .withdraw-2, .nav ul li.active a svg .withdraw-3, .nav ul li.active a svg .withdraw-4 {
            fill: none;
            stroke: #00be82; }

.contenta {
  float: right;
  width: 85%;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 100vh;
  background: url("../img/bg.png") no-repeat bottom center, -webkit-gradient(linear, left bottom, left top, from(#000c15), color-stop(50%, #00172a), to(#000c15));
  background: url("../img/bg.png") no-repeat bottom center, -webkit-linear-gradient(bottom, #000c15 0%, #00172a 50%, #000c15 100%);
  background: url("../img/bg.png") no-repeat bottom center, linear-gradient(0deg, #000c15 0%, #00172a 50%, #000c15 100%);
  background-size: 100%; }

.top-line {
  padding: 15px 40px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 32, 59, 0.25);
  -webkit-box-shadow: inset 0px 0px 80px 0px rgba(0, 68, 125, 0.25);
          box-shadow: inset 0px 0px 80px 0px rgba(0, 68, 125, 0.25);
  min-height: 56px; }
  .top-line > div {
    margin-left: 30px; }
  .top-line .user, .top-line .lang {
    position: relative; }
    .top-line .user > .dropdown, .top-line .lang > .dropdown {
      cursor: pointer; }
      .top-line .user > .dropdown + div a:not(.active), .top-line .lang > .dropdown + div a:not(.active) {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
  .top-line .user p, .top-line .user a {
    min-width: 70px; }

.dropdown {
  font: 14px/1.2 'GeometriaLight';
  color: #64849f;
  position: relative;
  padding-right: 20px; }
  /* .dropdown:before, .dropdown:after {
    height: 1px;
    width: 9px;
    top: 50%;
    background-color: #64849f; }
  .dropdown:before {
    -webkit-transform: rotate(50deg);
        -ms-transform: rotate(50deg);
            transform: rotate(50deg);
    right: 5.5px; }
  .dropdown:after {
    -webkit-transform: rotate(-50deg);
        -ms-transform: rotate(-50deg);
            transform: rotate(-50deg);
    right: 0; } */
  .dropdown + div {
    display: none;
    position: absolute;
    top: -16px;
    left: -21px;
    width: -webkit-calc(100% + 42px);
    width: calc(100% + 42px);
    height: auto;
    border: 1px solid #00be82;
    -webkit-border-radius: 5px;
            border-radius: 5px;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: #000f1c;
    padding: 15px 20px;
    z-index: 10; }
    .dropdown + div .dropdown:before {
      -webkit-transform: rotate(-50deg);
          -ms-transform: rotate(-50deg);
              transform: rotate(-50deg); }
    .dropdown + div .dropdown:after {
      -webkit-transform: rotate(50deg);
          -ms-transform: rotate(50deg);
              transform: rotate(50deg); }
    .dropdown + div a {
      display: block;
      outline: none;
      width: 100%;
      font: 14px/1.2 'GeometriaLight';
      color: #64849f; }
      .dropdown + div a:hover {
        color: #fff; }
    .dropdown + div p.active, .dropdown + div a.active {
      pointer-events: none; }
    .dropdown + div p:not(:first-child), .dropdown + div a:not(:first-child) {
      margin-top: 15px; }


  .main .title {
    margin: 0 auto 20px;
    background-color: rgba(0, 136, 93, 0.2);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 20px 4px;
    min-width: auto;
    text-align: center;
    position: relative; }
    .main .title:before, .main .title:after {
      position: absolute;
      content: '';
      top: 0;
      height: 0;
      border-bottom: 18px solid transparent;
      border-top: 18px solid rgba(0, 136, 93, 0.2); }
    .main .title:before {
      right: 100%;
      border-right: 15px solid rgba(0, 136, 93, 0.2);
      border-left: 15px solid transparent; }
    .main .title:after {
      left: 100%;
      border-right: 15px solid transparent;
      border-left: 15px solid rgba(0, 136, 93, 0.2); }

    .main .title h3 {
      font: 12px/1.2 'GeometriaLight';
      color: #00be82; }

.main_block {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 18, 33, 0.25)), to(rgba(0, 70, 129, 0.25)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  background-image: linear-gradient(0deg, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  -webkit-box-shadow: inset 0px 0px 70px 10px rgba(0, 68, 125, 0.1);
          box-shadow: inset 0px 0px 70px 10px rgba(0, 68, 125, 0.1);
  -webkit-border-radius: 5px;
          border-radius: 5px;
  position: relative; }
  .main_block:before, .main_block:after {
    background-color: #00be82;
    height: 2px;
    left: 0;
    right: 0;
    margin: auto; }
  .main_block:before {
    width: 80%;
    top: -1px; }
  .main_block:after {
    width: 15%;
    bottom: -1px; }

.main form input {
  display: block;
  margin: 0 auto 7px;
  padding: 15px 20px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  background: #000f1c;
  border: 1px solid #000f1c;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  outline: none;
  font-family: 'GeometriaLight'; }

.main form .field-wrap {
  position: relative;
  margin: 0 auto; }
  .main form .field-wrap a {
    color: #395cb7; }
  .main form .field-wrap.field-pass input {
    padding-right: 45px; }

.main form .create {
  margin-top: 20px; }



.main .welcome_message {
  color: #64849f;
  margin-bottom: 40px; }

.error_message, .success_message {
  border: 1px solid #df0024;
  margin-bottom: 25px;
  color: #fff;
  text-align: left;
  padding: 5px 20px;
  font-weight: normal;
  font-size: 14px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  max-width: 100%;
  margin: 0 auto 7px;
  display: none;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .error_message ul, .success_message ul {
    list-style-type: none; }

.error_field {
  color: #df0024;
  border-color: #df0024; }

.valid_field {
  border-color: #00be82; }

.success_message {
  border-color: #00be82; }

.button {
  font-weight: 100;
  display: inline-block;
  padding: 15px 20px 13px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 1px;
  color: #00be82;
  border: 1px solid #00be82;
  -webkit-border-radius: 25px;
          border-radius: 25px;
  background-color: #001b31;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-appearance: none; }
  .button:hover, .button:focus {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #fb7601;
    border-color: #fb7601; }
    .button:hover .circle, .button:focus .circle {
      border-color: #fb7601;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .button:hover .arrow, .button:hover .arrow:before, .button:hover .arrow:after, .button:focus .arrow, .button:focus .arrow:before, .button:focus .arrow:after {
      background-color: #fb7601;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
  .button:focus {
    background-color: #000f1c; }
  .button.blocked {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #003c66;
    border-color: #003c66;
    pointer-events: none; }
    .button.blocked .circle {
      border-color: #003c66;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .button.blocked .arrow, .button.blocked .arrow:before, .button.blocked .arrow:after {
      background-color: #003c66;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }

.currencies {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }
  .currencies > div {
    -webkit-flex-basis: 175px;
        -ms-flex-preferred-size: 175px;
            flex-basis: 175px;
    margin: 0.5em;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px 30px 10px 10px;
    position: relative;
    -webkit-border-radius: 5px;
            border-radius: 5px;
    background-color: rgba(0, 136, 93, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(0, 90, 130, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(0, 90, 130, 0.2); }
    .currencies > div.token .amount {
      margin-bottom: 0; }
    .currencies > div.token .sub {
      text-transform: none;
      color: #fff;
      line-height: 1;
      display: block;
      margin-bottom: 5px;
      font-size: 10px; }
    .currencies > div .amount {
      color: #fff;
      font: 18px/1 'GeometriaMedium';
      text-transform: uppercase;
      margin-bottom: 15px; }
      .currencies > div .amount span {
        font-size: 22px; }
    .currencies > div .rate {
      color: #00be82;
      font: 12px/1.2 'GeometriaLight';
      text-transform: uppercase; }
    .currencies > div a {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 12px;
      height: 12px;
      color: #ff7800;
      font: 12px/1 'GeometriaMedium'; }
      .currencies > div a span {
        width: 12px;
        height: 1px;
        background-color: #ff7800;
        display: block;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0; }
      .currencies > div a:before {
        width: 8px;
        height: 1px;
        background-color: #ff7800;
        bottom: 0;
        right: 1px; }
      .currencies > div a:after {
        height: 9px;
        width: 1px;
        background-color: #ff7800;
        top: 3px;
        right: 0; }
  .currencies .btc {
    background-color: rgba(247, 147, 26, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(247, 147, 26, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(247, 147, 26, 0.2); }
    .currencies .btc .rate {
      color: #f7931a; }
  .currencies .bch {
    background-color: rgba(108, 182, 37, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(108, 182, 37, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(108, 182, 37, 0.2); }
    .currencies .bch .rate {
      color: #6cb625; }
  .currencies .eth {
    background-color: rgba(137, 146, 178, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(137, 146, 178, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(137, 146, 178, 0.2); }
    .currencies .eth .rate {
      color: #8992b2; }
  .currencies .ltc {
    background-color: rgba(187, 187, 187, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(187, 187, 187, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(187, 187, 187, 0.2); }
    .currencies .ltc .rate {
      color: #bbbbbb; }
  .currencies .dash {
    background-color: rgba(0, 149, 211, 0.15);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(0, 149, 211, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(0, 149, 211, 0.2); }
    .currencies .dash .rate {
      color: #0095d3; }

.faq ul {
  list-style-type: none;
  padding: 40px; }

.faq li {
  border-bottom: 1px solid #012e55;
  padding: 15px 0; }
  .faq li p {
    font: 14px/1.2 'GeometriaLight'; }

.faq .item:hover {
  cursor: pointer; }

.faq .item p {
  position: relative;
  padding-right: 25px;
  color: #00be82; }
  .faq .item p:after {
    content: '+';
    font-size: 32px;
    line-height: 0.4;
    right: 5px;
    top: -webkit-calc(50% - 8px);
    top: calc(50% - 8px);
    height: 16px;
    width: 16px;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }

.faq .item.active p:after {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg); }

.faq .hint {
  display: none;
  margin-top: 20px; }
  .faq .hint p {
    color: #fff; }

.history_table.withdraw_history .filters, .reports.withdraw_history .filters {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.history_table .wrap, .reports .wrap {
  padding: 10px 40px 55px; }

.history_table .filters, .reports .filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 40px; }

.history_table .operation ul, .reports .operation ul {
  left: -12px; }

.history_table .operation.bounty p, .history_table .operation.bounty li, .reports .operation.bounty p, .reports .operation.bounty li {
  text-align: left;
  min-width: 210px;
  line-height: 1; }

.history_table .operation.bounty p.active:before, .reports .operation.bounty p.active:before {
  right: 8px; }

.history_table .operation.bounty p.active:after, .reports .operation.bounty p.active:after {
  right: 2px; }

.history_table .date ul, .reports .date ul {
  right: -9px; }
  .history_table .date ul li, .reports .date ul li {
    text-align: right;
    padding-right: 23px; }
    .history_table .date ul li.active, .reports .date ul li.active {
      padding-right: 23px; }

.history_table .operation, .history_table .date, .reports .operation, .reports .date {
  position: relative; }
  .history_table .operation p, .history_table .operation li, .history_table .date p, .history_table .date li, .reports .operation p, .reports .operation li, .reports .date p, .reports .date li {
    cursor: pointer;
    font: 14px/1 'GeometriaLight';
    color: #64849f; }
  .history_table .operation p, .history_table .date p, .reports .operation p, .reports .date p {
    padding-right: 23px; }
  .history_table .operation ul, .history_table .date ul, .reports .operation ul, .reports .date ul {
    list-style-type: none;
    position: absolute;
    top: -14px;
    margin: auto;
    -webkit-border-radius: 5px;
            border-radius: 5px;
    background: #000f1c;
    border: 1px solid #00be82;
    display: none;
    padding: 10px 8px;
    min-width: 130px;
    z-index: 10; }
  .history_table .operation .active, .history_table .date .active, .reports .operation .active, .reports .date .active {
    position: relative; }
    .history_table .operation .active:before, .history_table .operation .active:after, .history_table .date .active:before, .history_table .date .active:after, .reports .operation .active:before, .reports .operation .active:after, .reports .date .active:before, .reports .date .active:after {
      width: 8px;
      height: 1px;
      background: #64849F;
      bottom: 5px; }
    .history_table .operation .active:before, .history_table .date .active:before, .reports .operation .active:before, .reports .date .active:before {
      right: 6px;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
    .history_table .operation .active:after, .history_table .date .active:after, .reports .operation .active:after, .reports .date .active:after {
      right: 0;
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .history_table .operation li, .history_table .date li, .reports .operation li, .reports .date li {
    padding: 4px;
    width: 100%;
    -webkit-border-radius: 5px;
            border-radius: 5px; }
    .history_table .operation li.year, .history_table .date li.year, .reports .operation li.year, .reports .date li.year {
      color: #fff;
      pointer-events: none; }
    .history_table .operation li:not(:last-child), .history_table .date li:not(:last-child), .reports .operation li:not(:last-child), .reports .date li:not(:last-child) {
      margin-bottom: 7px; }
    .history_table .operation li.active, .history_table .date li.active, .reports .operation li.active, .reports .date li.active {
      padding-right: 16px; }
      .history_table .operation li.active:before, .history_table .date li.active:before, .reports .operation li.active:before, .reports .date li.active:before {
        bottom: 10px;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        right: 6px; }
      .history_table .operation li.active:after, .history_table .date li.active:after, .reports .operation li.active:after, .reports .date li.active:after {
        bottom: 10px;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        right: 0px; }
    .history_table .operation li:hover, .history_table .date li:hover, .reports .operation li:hover, .reports .date li:hover {
      background-color: rgba(100, 132, 159, 0.1); }

.table_wrap .button {
  margin: 0 auto;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.table_wrap table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px; }
  .table_wrap table tr {
    vertical-align: bottom; }
  .table_wrap table td, .table_wrap table th {
    border-bottom: 1px solid #012e55;
    padding: 10px 0;
    text-align: left; }
    .table_wrap table td:not(:last-child), .table_wrap table th:not(:last-child) {
      padding-right: 10px; }
  .table_wrap table td:before {
    margin-right: 20px;
    content: attr(data-th) ": ";
    text-align: left;
    display: none; }
  .table_wrap table th, .table_wrap table td:before {
    color: #64849f;
    font: 12px/1.2 'Geometria';
    text-transform: uppercase; }
  .table_wrap table .date {
    font: 12px/1.2 'GeometriaLight';
    color: #fff; }
  .table_wrap table .order, .table_wrap table .currency {
    font: 14px/1.2 'GeometriaLight';
    color: #fff; }
    .table_wrap table .order span, .table_wrap table .currency span {
      display: block;
      margin-top: 5px;
      color: #64849f;
      font: 12px/1.2 'GeometriaLight'; }
  .table_wrap table .currency {
    text-transform: uppercase; }
  .table_wrap table .amount {
    font: 14px/1.2 'GeometriaLight';
    color: #00be82; }
    .table_wrap table .amount.minus {
      color: #f97601; }
  .table_wrap table .status {
    font: 14px/1.2 'GeometriaLight';
    color: #fff; }
    .table_wrap table .status.done {
      color: #00be82; }
    .table_wrap table .status.fail {
      color: #f97601; }

.success {
  position: absolute;
  padding: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  display: none;
  border: 1px solid #00be82;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  background-color: #000f1c;
  z-index: 100;
  text-align: center; }

.success_icon {
  display: inline-block;
  height: 140px;
  width: 140px;
  position: relative;
  border: 1px solid #00be82;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  margin: 0px auto 50px; }
  .success_icon:after {
    width: 25px;
    height: 47px;
    border-right: 1px solid #00be82;
    border-bottom: 1px solid #00be82;
    -webkit-transform: rotate(45deg) translate(0%, -15%);
        -ms-transform: rotate(45deg) translate(0%, -15%);
            transform: rotate(45deg) translate(0%, -15%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  .success .close {
    color: #64849f;
    margin-top: 20px;
    font: 14px/1.2 'GeometriaLight';
    display: inline-block;
    text-decoration: underline;
    cursor: pointer; }
  

.show_password {
  width: 20px;
  height: 17px;
  top: 25px;
  right: 0;
  -webkit-transform: translate(-100%, -50%);
      -ms-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  cursor: pointer; }
  .show_password svg {
    width: 100%;
    height: 100%; }
    .show_password svg path {
      fill: #64849f; }
  .show_password.active svg path {
    fill: #fff; }

.nicescroll-rails {
  background-color: #000f1c;
  width: 4px !important;
  opacity: 1 !important; }

.nicescroll-cursors {
  border: none !important;
  -webkit-border-radius: 5px !important;
          border-radius: 5px !important;
  background-color: rgba(100, 132, 159, 0.5) !important; }

.loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(0,0,0,.7);
  display: none; }
  .loader__spinner {
    min-width: 50px;
    width: 10%;
    height: 10%;
    min-height: 50px; }
  .loader__overlay {
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation: loader-spinner-rotate 1s infinite linear;
            animation: loader-spinner-rotate 1s infinite linear; }
  .loader__circle-gradient:nth-child(1) {
    stop-color: #00be82; }
  .loader__circle-gradient:nth-child(2), .loader__circle-gradient:nth-child(3) {
    stop-color: #00a6ad; }
  .loader__circle-gradient:nth-child(4) {
    stop-color: #0091ce; }

@-webkit-keyframes loader-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loader-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@media only screen and (max-width: 1175px) {
  .nav ul li.user a svg {
    width: 18px;
    height: 18px; }
  .nav ul li a {
    padding-right: 0px;
    padding-left: 15px; }
    .nav ul li a svg {
      margin-right: 10px;
      width: 20px;
      height: 20px; } }

@media only screen and (max-width: 992px) {
  .nav .logo_wrap img {
    width: -webkit-calc(100% - 10px);
    width: calc(100% - 10px);
    max-width: 60px; }
  .nav .logo_main {
    display: none; }
  .nav .logo_mini {
    display: block; }
  .nav ul li a {
    padding: 25px 0px;
    text-align: center; }
    .nav ul li a span {
      width: 100%;
      display: block; }
    .nav ul li a svg {
      margin: 0 0 10px 0; }
  .main .title, .main .title.basic {
    min-width: auto !important; } }

@media only screen and (max-width: 768px) {
  .nav ul li a {
    padding: 15px 0; }
    .nav ul li a span {
      display: none; }
    .nav ul li a svg {
      margin-bottom: 0; }
  .main {
    margin: 20px; }
    .main .title.basic, .main .title {
      -webkit-border-radius: 5px;
              border-radius: 5px; }
      .main .title.basic:before, .main .title.basic:after, .main .title:before, .main .title:after {
        display: none; } }

@media only screen and (max-width: 480px) {
  .nav {
    width: 70px;
    z-index: 10; }
  .content {
    width: -webkit-calc(100% - 70px);
    width: calc(100% - 70px); }
  .top-line {
    padding-left: 20px; } }
