.book label{
    color: white;
    font-size: 9px;
    text-transform: uppercase;
}

.book .btn {
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    padding: 13px 32px;
    font: 15px/0.956 'UbuntuRegular';
    color: #ffffff;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    background-color: #6c1a74;
    display: inline-block;
    height: 40px;
}


.book .input{
    font: 13px/1 'UbuntuRegular';
    color: rgba(27, 14, 73, 0.451);
    outline: none;
    border: 2px solid #6c1a74;
    height: 42px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    padding-left: 10px;
    border-radius: 5px;
}

.book select{
    border: 2px solid #6c1a74 !important;
    border-radius: 5px;
    height: 42px !important;
}
