.draw-row{
    width: 100%;
}
.draw-col{
    width: 50%;
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
}
.p-1{
    padding: 5px;
}
.draw-box{
    border: 1px solid gray;
    padding: 15px;
}
.draw-dropbox{
    width: 100%;
    height: 48px;
}
.mt-11{
    margin-top: 11px;
}