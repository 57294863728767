body {
  margin: 0;
  font-family: "PromptRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "PromptRegular" !important;
}
code {
  font-family: "PromptRegular";
}
.profile td {
  border: none !important;
}

.loader-payments {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #7ee8a1;
  border-bottom: 6px solid #7ee8a1;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.no-loader-payments {
  border: 6px solid #d6d6d6;
  border-radius: 50%;
  border-top: 6px solid #d6d6d6;
  border-bottom: 6px solid #d6d6d6;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confermation {
  position: absolute;
  margin-top: -30px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 14px;
}

.withnote {
  padding-left: 15px;
  font-size: 14px;
}

.withnote li {
  list-style: disc !important;
  margin-top: 5px;
}

.member {
  background: #f9f6fb;
  text-align: center;
}

.member .name {
  font: 18px/1.2 "UbuntuMedium";
  text-transform: uppercase;
  color: #041b2d;
  margin: 0px;
  padding: 5px;
}

.member .role {
  font: 16px/1.2 "UbuntuLight";
  color: #545b61;
  margin-top: 15px;
  padding: 5px;
}

.member .photo {
  padding-top: 10px;
}

.member-wrap {
  margin: 0px 10px;
}

.member-wrap p {
  padding: 20px;
  text-align: center;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.blackButton {
  display: none;
}

whiteButton {
  display: block;
}

.member-info p {
  text-align: justify;
}

.member .read_more {
  margin-left: -55px;
}

.faq .item p:after {
  content: none !important;
}

.faq .item {
  margin-bottom: 0px !important;
}

.faq .item p {
  color: black !important;
}

.faq li p {
  font: inherit !important;
}

.faq .hint p {
  color: black !important;
  margin-bottom: 10px;
}

.faq li {
  border-bottom: none !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.wrappervd {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.wrappervd iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .profile_main .main-block-custom {
    width: 100%;
    margin-bottom: 15px;
  }
  .profile_main .main-block-custom h3 {
    font-size: 15px;
  }
  .address_main {
    display: inherit !important;
  }
  .cardToken {
    padding: 15px 10px;
  }
  .main-div {
    padding: 25px !important;
  }
  .nav .logo_wrap img {
    height: 25px;
  }
  .nav ul li a {
    justify-content: center;
  }
  .hd-video {
    padding-left: 0 !important;
  }
  .hd-video iframe {
    width: 100% !important;
  }
  .wpBtn {
    font-size: 11px !important;
  }
  .our_customer_main {
    display: none !important;
  }
  #team .content {
    padding: 16px 0 !important;
  }
}

.uf-list {
  padding: 15px !important;
}
.uf-list li {
  margin-top: 5px !important;
  list-style: disc !important;
}

@media only screen and (max-width: 480px) {
  .content {
    width: 100% !important;
  }

  .hide-sb {
    width: 60px !important;
  }
  .nav {
    left: -70px !important;
  }
  .profile_main,
  .main-div {
    width: 100% !important;
    padding: 15px !important;
  }
}

.contact-custom {
  max-width: none !important;
  outline: none;
  display: block;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #b2b2b2;
  color: #041b2d;
  font: 14px/1 "PromptRegular";
  height: 42px;
  background: white;
  border-radius: 0px;
  margin: 0px 0px 20px 0px;
}

.team.owl-carousel .member .member-info,
#member-info-popup .member .member-info {
  height: 90px !important;
}
.member .read_more {
  margin-left: 0px !important;
}

.wp_btn_wrap {
  display: flex;
}

.wpBtn {
  width: 49%;
  background-image: linear-gradient(to right, #8e209e 0%, #1b0b52 100%);
  height: 42px;
  font-size: 14px;
  color: white;
  border: none;
  padding: 10px !important;
  margin: 10px 2px 0 !important;
  cursor: pointer;

  position: inherit !important;
}

.wpBtn:hover {
  background: #251c7a;
  color: white;
}
.private-sale-presale .timer-container {
  top: -50px;
  right: -150px;
  min-width: 445px;
}
.show_password {
  display: none !important;
}

.isCode {
  width: 20%;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  border-color: #6c1a74 !important;
  background-color: #fff !important;
  font-size: 14px;
  color: #6c2474 !important;
  font-family: "GeometriaMedium" !important;
}

.inputiconsl {
  position: absolute;
  right: 15px;
  top: 7px;
  font-size: 18px;
  font-weight: 900;
  color: #6c1a74;
  text-transform: uppercase;
}
/* Rajesh */
/* body{
  background: #f7f7f7;
} */
.nav-custom {
  box-shadow: none !important;
}
.profile_main,
.main-div {
  background: transparent !important;
}
.main-block-custom {
  -webkit-box-shadow: 0px 1px 5px #e7e7e7 !important;
  box-shadow: 0px 1px 5px #e7e7e7 !important;
}
.cardToken {
  background: #fff;
  min-height: 265px;
  height: auto !important;
}
.cardToken ul {
  max-width: 320px;
  margin-top: 10px;
}
.cardToken ul li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 3px;
}
.token_sale_main .custom-col2 {
  width: 100%;
}
.token_sale_main .btn-edit {
  background: rgb(108, 37, 116);
  color: #fff;
  border: none;
}
.txt_green {
  color: green;
}
.address_main {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: rgb(108, 37, 116);
  color: #fff;
  padding: 13px;
  border-radius: 8px;
  justify-content: center;
}
.address_code {
  margin-left: 10px;
}
.address_code p {
  font-weight: 700;
  font-size: 16px;
}
.col-sm-12 {
  width: 100%;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.selectcurr {
  border-radius: 5px;
}
.width-50 {
  width: 50%;
}
.mt-15 {
  margin-top: 15px;
}
.ul-text {
  margin: 10px 0;
}
.ul-text li {
  text-align: left;
}
.text-left {
  text-align: left;
}

.e-t-b {
  margin-top: 33px;
  font-size: 11px;
  text-align: left;
}
a.refresh_coin {
  position: absolute;
  right: 7px;
  top: 7px;
  color: #fff;
  font-size: 30px;
}
.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hide {
  display: none;
}
.changePassword {
  display: none !important;
}
.nav ul li a {
  display: flex !important;
  align-items: center;
  color: #fff !important;
  font-family: "PromptRegular" !important;
}
.nav ul li a i.mdi {
  font-size: 22px;
  color: #fff;
}
/* .top-line-custom{
  display: none !important
} */
.profile_main .main-block-custom {
  margin-right: 15px;
  padding: 15px;
}
.profile_main .table_wrap {
  width: 100%;
}
.profile_main .profile td {
  padding: 0;
}
.profile td:first-child {
  width: 15%;
}
.n-d {
  justify-content: flex-start !important;
}
ul {
  margin-bottom: 0;
}

.my-popup-background-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  font-size: 100%;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  z-index: 9999;
}

/* master popup div container */
.my-popup-container {
  position: fixed;
  width: 890px;
  height: 410px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("public/landing/img/welcome1.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 23%;
  background-color: #ffffff;
  padding: 15px 50px 0 50px;
  text-align: left;
  border-radius: 0px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mlctr-close {
  display: block;
  position: absolute;
  z-index: 1;
  color: #fff;
  right: 18px;
  top: 18px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.5s, opacity ease-in-out 0.25s;
  -moz-transition: -moz-transform 0.5s, opacity ease-in-out 0.25s;
  -o-transition: -o-transform 0.5s, opacity ease-in-out 0.25s;
  transition: transform 0.5s, opacity ease-in-out 0.25s;
}

.my-popup-container h1 {
  width: 100%;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
  padding: 0 0 0 0;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
}
.my-popup-container h1 b {
  font-size: 40px;
  line-height: 40px;
}
.my-popup-container h2 {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
  padding: 7px 10px 7px 10px;
  text-align: left;
  margin: 0;
  box-sizing: border-box;
}
.my-popup-container .btn {
  display: block;
  width: 100%;
  max-width: 380px;
  margin: 15px auto;
}
.my-popup-container p,
.my-popup-container a {
  color: #fff !important;
}
.welcome_popup_modal .t-logo {
  height: 45px;
}

.card-img-col {
  align-items: center;
  justify-content: center;
  display: flex !important;
}
.card-img-col img {
  width: 100%;
}
.welcome_popup_modal .social-login .ui.button {
  margin-bottom: 12px;
}

@media (max-width: 767px) and (min-width: 320px) {
  .banner {
    flex-direction: column;
  }
  .my-popup-container {
    width: 100%;
    overflow-y: auto;
    max-height: 400px;
    padding: 10px 0px 10px 0px;
  }
  .card-img-col img {
    height: 160px;
    width: auto;
  }
  .my-popup-container .btn {
    max-width: 250px;
  }
}

.banner {
  display: flex;
  align-items: center;
  padding: 1.25em 2em;
  border-radius: 5px;

  color: #fff;
}
.banner--gradient-bg {
  background: linear-gradient(-30deg, #1d1350, #1d1350 45%, #6a0074 45%);
}
.banner__logo-wrapper {
  padding: 0 0.5em;
  margin-right: 20px;
}
.banner__title {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
.banner__text {
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 1.4;
}
.banner__cta {
  display: inline;

  margin-left: 1em;
  border-radius: 2px;

  font-size: 0.75rem;
  color: #4b8ee5;
  white-space: nowrap;
  text-transform: uppercase;

  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.15);
}

.banner__cta img {
  height: 65px;
}
span.bonus_span {
  position: absolute;
  z-index: 1;
  top: 7px;
  /* left: 194px; */
  right: 70px;
}

.why_H_List .faq__list {
  list-style-type: disc;
  padding: 0 15px;
  margin-bottom: 15px;
}
.why_H_List .faq__list li {
  margin-bottom: 10px !important;
}

.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 78px;
  z-index: 1;
}
