.partner{
    stroke:#64849f;stroke-miterlimit:10;stroke-width:5px;
}

.card-class{
    width: 22px;
    height: 22px;
    margin-right: 8px;
    vertical-align: bottom;
}

/*.active{*/
    /*background-color: #6c1a74 !important;*/
    /*border-right-color: #fff !important;*/
/*}*/


