.top-line-custom {
  -webkit-box-shadow: inset 0px 0px 80px 0px rgba(108, 26, 116, 0.1) !important;
          box-shadow: inset 0px 0px 80px 0px rgba(108, 26, 116, 0.1) !important;
  background: #1d1350 !important; }

.dropdown-custom {
  border-color: #1d1350 !important;
  background-color: #1d1350 !important;
  border: 1px solid #fff !important; }

.topline-font-custom {
  color: #d8d8d8 !important; }
  /* .topline-font-custom:before, .topline-font-custom:after {
    background-color: #d8d8d8 !important; } */

.dropdown + div .topline-font-custom:hover {
  color: #fff !important; }

.nav-custom {
  background: #1d1350 !important;
  z-index: 1; }
  .nav-custom li:hover {
    border-right-color: #fff !important; }
  .nav-custom li.active {
    background-color: #6c1a74 !important;
    border-right-color: #fff !important; }

.nav-font-custom {
  color: #d8d8d8 !important; }

.nav-icon-custom svg path, .nav-icon-custom svg .circle {
  fill: #d8d8d8 !important; }

.nav-icon-custom svg .partner {
  stroke: #d8d8d8 !important; }

.nav-icon-custom svg .withdraw-1, .nav-icon-custom svg .withdraw-2, .nav-icon-custom svg .withdraw-3, .nav-icon-custom svg .withdraw-4 {
  fill: none !important;
  stroke: #d8d8d8 !important; }

.nav-icon-custom:hover, .nav-icon-custom.active {
  background-color: #6c1a74 !important; }
  .nav-icon-custom:hover a svg path.partner, .nav-icon-custom.active a svg path.partner {
    stroke: #FFF !important; }
  .nav-icon-custom:hover a span, .nav-icon-custom.active a span {
    color: #fff !important; }
  .nav-icon-custom:hover a svg path, .nav-icon-custom:hover a svg .circle, .nav-icon-custom.active a svg path, .nav-icon-custom.active a svg .circle {
    fill: #fff !important; }
  .nav-icon-custom:hover a svg .withdraw-1, .nav-icon-custom:hover a svg .withdraw-2, .nav-icon-custom:hover a svg .withdraw-3, .nav-icon-custom:hover a svg .withdraw-4, .nav-icon-custom.active a svg .withdraw-1, .nav-icon-custom.active a svg .withdraw-2, .nav-icon-custom.active a svg .withdraw-3, .nav-icon-custom.active a svg .withdraw-4 {
    fill: none !important;
    stroke: #fff !important; }

.nav-icon-custom:hover {
  background-color: rgba(108, 26, 116, 0.7) !important; }

.nav-icon-custom.active:hover {
  background-color: #6c1a74 !important; }

.currencies .arrow-link-custom {
  color: #f163ff !important; }
  .currencies .arrow-link-custom:before, .currencies .arrow-link-custom:after, .currencies .arrow-link-custom span {
    background-color: #f163ff !important; }

.main-token-custom {
  background-color: #6c1a74 !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(108, 26, 116, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(108, 26, 116, 0.1) !important; }
  .main-token-custom .sub {
    color: #fff !important; }
  .main-token-custom .amount {
    color: #fff !important; }
  .main-token-custom .rate {
    color: #fff !important; }
  .main-token-custom:nth-child(2) {
    background-color: #1d1350 !important; }

.btc-token-custom {
  background-color: #535b60 !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(29, 19, 80, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(29, 19, 80, 0.1) !important; }
  .btc-token-custom .sub {
    color: #fff !important; }
  .btc-token-custom .amount {
    color: #fff !important; }
  .btc-token-custom .rate {
    color: #fff !important; }

.bch-token-custom {
  background-color: white !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(255, 85, 34, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(255, 85, 34, 0.1) !important; }
  .bch-token-custom .sub {
    color: #1d1350 !important; }
  .bch-token-custom .amount {
    color: #1d1350 !important; }
  .bch-token-custom .rate {
    color: #1d1350 !important; }

.eth-token-custom {
  background-color: #25208d !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(95, 178, 15, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(95, 178, 15, 0.1) !important; }
  .eth-token-custom .sub {
    color: #fff !important; }
  .eth-token-custom .amount {
    color: #fff !important; }
  .eth-token-custom .rate {
    color: #fff !important; }

.ltc-token-custom {
  background-color: #b741c7 !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(255, 192, 8, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 30px 0px rgba(255, 192, 8, 0.1) !important; }
  .ltc-token-custom .sub {
    color: #fff !important; }
  .ltc-token-custom .amount {
    color: #fff !important; }
  .ltc-token-custom .rate {
    color: #fff !important; }

.dash-token-custom {
  background-color: #051b2b !important;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 20px 0px rgba(8, 186, 255, 0.1) !important;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25), inset 0px 0 20px 0px rgba(8, 186, 255, 0.1) !important; }
  .dash-token-custom .sub {
    color: #fff !important; }
  .dash-token-custom .amount {
    color: #fff !important; }
  .dash-token-custom .rate {
    color: #fff !important; }

.account-content-custom {
  background: #fff;
  -webkit-background-size: 100% 100%;
          background-size: 100%; }

.title-custom {
  background-color: rgba(108, 26, 116, 0.9) !important; }
  .title-custom:before, .title-custom:after {
    border-top-color: rgba(108, 26, 116, 0.9) !important; }
  .title-custom:before {
    border-right-color: rgba(108, 26, 116, 0.9) !important; }
  .title-custom:after {
    border-left-color: rgba(108, 26, 116, 0.9) !important; }
  .title-custom h2 {
    color: #fff; }
  .title-custom h3 {
    color: #d8d8d8 !important; }

.arrow-link-custom {
  color: #1d1350 !important; }
  .arrow-link-custom span, .arrow-link-custom:before, .arrow-link-custom:after {
    background-color: #1d1350 !important; }

.account-title-color-custom {
  color: #6c1a74 !important;
  font-family: 'GeometriaMedium' !important; }

.font-emphasis-custom {
  color: #6c1a74 !important;
  font-family: 'GeometriaMedium' !important; }

.font-add-emphasis-custom {
  color: #1d1350 !important; }

.dropdown-custom.li-hover-custom li:hover {
  background-color: rgba(108, 26, 116, 0.5) !important; }

.dropdown-custom.li-hover-custom .content-font-color-custom {
  color: #d8d8d8 !important; }
  .dropdown-custom.li-hover-custom .content-font-color-custom:before, .dropdown-custom.li-hover-custom .content-font-color-custom:after {
    background-color: #d8d8d8 !important; }

.dropdown-custom.li-hover-custom .year.account-title-color-custom {
  color: #f27efd !important; }

.loader-custom .loader__circle-gradient:nth-child(1) {
  stop-color: #1d1350 !important; }

.loader-custom .loader__circle-gradient:nth-child(2), .loader-custom .loader__circle-gradient:nth-child(3) {
  stop-color: #6c1a74 !important; }

.loader-custom .loader__circle-gradient:nth-child(4) {
  stop-color: #f92f7a !important; }

.filters .content-font-color-custom:before, .filters .content-font-color-custom:after {
  background-color: #000104 !important; }

.nicescroll-rails {
  background-color: rgba(108, 26, 116, 0.4) !important; }

.nicescroll-cursors {
  background-color: rgba(108, 26, 116, 0.8) !important; }

.wallet-custom {
  color: #fff !important;
  border-color: #1d1350 !important;
  background-color: #1d1350 !important; }

.currency-custom {
  color: #1d1350 !important;
  border-color: #1d1350 !important;
  background: #fff !important; }
  .currency-custom.active {
    color: #fff !important;
    border-color: #1d1350 !important;
    background-color: #1d1350 !important; }

.token-custom:before, .token-custom:after {
  background-color: #6c1a74 !important; }

.token-custom input {
  background-color: rgba(108, 26, 116, 0.5) !important; }

.token-custom label {
  color: #6c1a74 !important; }

.token-custom.rate {
  background-color: #1d1350 !important;
  color: #fff !important; }

.counter-custom svg .counter1 {
  fill: #6c1a74 !important; }

.counter-custom svg .counter2 {
  fill: #FFFFFF !important; }

.bind-custom .bind {
  stroke: #6c1a74; }

.table-undreline-custom td:after {
  background: #312b56 !important; }

.history-undreline-custom th, td {
  border-bottom-color: #4c4863 !important; }

.table-td-custom td {
  color: #6c1a74 !important; }

.social-custom a {
  border-color: #6c1a74 !important; }
  .social-custom a svg path, .social-custom a svg circle {
    fill: #6c1a74 !important; }
    .social-custom a svg path.fill-none, .social-custom a svg circle.fill-none {
      fill: none !important;
      stroke: transparent !important; }
  .social-custom a svg path {
    stroke: transparent !important; }
  .social-custom a svg circle {
    stroke: #6c1a74 !important; }
  .social-custom a:hover {
    border-color: #1d1350 !important; }
    .social-custom a:hover svg path {
      fill: #1d1350 !important; }
      .social-custom a:hover svg path.fill-none {
        fill: none !important;
        stroke: transparent !important; }

.qr-popup-custom {
  border-color: #6c1a74 !important;
  background-color: #fff !important; }

.choose-btn-custom {
  color: #fff !important;
  border-color: #6c1a74 !important;
  background-color: #6c1a74 !important; }

.mark-custom {
  color: #6c1a74 !important; }

.withdraw-custom li {
  background-color: #1d1350 !important; }
  .withdraw-custom li span {
    color: #d9dbe7 !important; }

.withdraw-active-custom, .arrows-custom {
  color: #fff !important;
  font-family: 'GeometriaMedium' !important; }
  .withdraw-active-custom:before, .withdraw-active-custom:after, .arrows-custom:before, .arrows-custom:after {
    background-color: #6c1a74 !important; }
  .withdraw-active-custom .up:before, .withdraw-active-custom .up:after, .withdraw-active-custom .down:before, .withdraw-active-custom .down:after, .arrows-custom .up:before, .arrows-custom .up:after, .arrows-custom .down:before, .arrows-custom .down:after {
    background-color: #6c1a74 !important; }
  .withdraw-active-custom span, .arrows-custom span {
    color: #6c1a74 !important; }

.withdraw-active-custom span {
  font-size: 10px !important; }

li.withdraw-active-custom:before, li.withdraw-active-custom:after {
  background-color: #d9dbe7 !important; }

.currency_list .content-font-color-custom.active {
  color: #6c1a74 !important; }

.input-icon-custom svg path {
  fill: #6c1a74 !important; }

.output-icon-custom svg path {
  fill: #1d1350 !important; }

.faq-underline-custom li {
  border-bottom-color: rgba(108, 26, 116, 0.2) !important; }

.main-block-custom {
  background: #fff !important;
   }
  .main-block-custom::before, .main-block-custom:after {
    background: #6c1a74 !important; }

.content-font-color-custom {
  color: #000104 !important;
  font-family: 'GeometriaMedium' !important; }

.form-placeholder-custom {
  -moz-placeholder-color: #6c1a74 !important;
  -ms-input-placeholder-color: #6c1a74 !important; }
  .form-placeholder-custom ::-webkit-input-placeholder {
    color: #6c1a74 !important; }
  .form-placeholder-custom ::-moz-placeholder {
    color: #6c1a74 !important; }
  .form-placeholder-custom input:-webkit-autofill, .form-placeholder-custom input:-webkit-autofill:hover, .form-placeholder-custom input:-webkit-autofill:focus, .form-placeholder-custom input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -o-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #6c1a74 !important;
    color: #6c1a74 !important; }

.input-custom {
  border-color: #6c1a74 !important;
  background-color: #fff !important;
  color: #1d1350 !important;
  font-family: 'GeometriaMedium' !important; }
  .input-custom:focus {
    border-color: #1d1350 !important; }

.show-password-custom svg path {
  fill: #6c1a74; }

.show-password-custom.active svg path {
  fill: #1d1350; }

.button-custom {
  color: #fff !important;
  background-color: #6c1a74 !important;
  border-color: #6c1a74 !important; }
  .button-custom:hover {
    color: #fff !important;
    background-color: #1d1350 !important;
    border-color: #1d1350 !important; }
    .button-custom:hover .circle-custom {
      border-color: #1d1350 !important; }
    .button-custom:hover .arrow-custom {
      background: #1d1350 !important; }
      .button-custom:hover .arrow-custom:before, .button-custom:hover .arrow-custom:after {
        background: #1d1350 !important; }
  .button-custom.change:hover, .button-custom.add:hover {
    background: #1d1350 !important; }

.circle-custom {
  border-color: #6c1a74 !important; }

.arrow-custom {
  background: #6c1a74 !important; }
  .arrow-custom::before {
    background: #6c1a74 !important; }
  .arrow-custom::after {
    background: #1d1350 !important; }

.button-custom.blocked {
  color: #1d1350 !important;
  border-color: #1d1350 !important;
  background: #fff !important; }

.button-custom.blocked .circle-custom {
  border-color: #1d1350 !important; }

.button-custom.blocked .arrow-custom {
  background: #1d1350 !important; }
  .button-custom.blocked .arrow-custom::before {
    background: #1d1350 !important; }
  .button-custom.blocked .arrow-custom::after {
    background: #1d1350 !important; }

.underline-custom:before {
  background: #d9dbe7 !important; }

.error-message-custom {
  border-color: #df0024 !important;
  color: #000 !important; }

.success-message-custom {
  border-color: #1fb28f !important;
  color: #000 !important; }

.success-icon-custom {
  border-color: #6c1a74 !important; }
  .success-icon-custom:after {
    border-color: #6c1a74 !important; }

.success {
  border-color: #6c1a74 !important;
  background-color: #fff !important; }

@media only screen and (max-width: 992px) {
  .nav .logo_wrap img {
    max-width: 45px; } }

    .profile_main .wrap{
    padding: 15px;
    }