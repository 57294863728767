.email_verification_main_bg{
    background: #f9f6fb;
    
}
.verification_box{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
    padding: 30px;
  background: #fff;
  max-width: 440px;
}
.verification_box h2{
    color: #6c1a74;
}
.verification_box .m_p{
    margin: 20px 0;
}
.verification_box .btn{
    margin: 0 0 20px;
    width: 100%;
}
.verification_box ul{
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.verification_box ul li{
    font-size: 14px;
    list-style: circle;
    line-height: 25px;
}
a.btn_link {
    color: #6c1a74;  
    font-weight: 700;
    font-size: 14px;
}
.verification_box .mailbox_img{
    display: block;
    margin: 0 auto;
}