@font-face {
  font-family: 'GeometriaHeavy';
  src: url("../fonts/Geometria-Heavy.eot");
  src: local("Geometria Heavy"), local("Geometria-Heavy"), url("../fonts/Geometria-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Heavy.woff") format("woff"), url("../fonts/Geometria-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaMedium';
  src: url("../fonts/Geometria-Medium.eot");
  src: local("Geometria Medium"), local("Geometria-Medium"), url("../fonts/Geometria-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Medium.woff") format("woff"), url("../fonts/Geometria-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url("../fonts/Geometria.eot");
  src: local("Geometria"), url("../fonts/Geometria.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria.woff") format("woff"), url("../fonts/Geometria.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaBold';
  src: url("../fonts/Geometria-Bold.eot");
  src: local("Geometria Bold"), local("Geometria-Bold"), url("../fonts/Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Bold.woff") format("woff"), url("../fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaExtraBold';
  src: url("../fonts/Geometria-ExtraBold.eot");
  src: local("Geometria ExtraBold"), local("Geometria-ExtraBold"), url("../fonts/Geometria-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-ExtraBold.woff") format("woff"), url("../fonts/Geometria-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'GeometriaLight';
  src: url("../fonts/Geometria-Light.eot");
  src: local("Geometria Light"), local("Geometria-Light"), url("../fonts/Geometria-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Light.woff") format("woff"), url("../fonts/Geometria-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.button {
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600; }

.arrow::before, .arrow::after, .forgot a::before, p.info a::before, .success_icon:after, .show_password, .icon:after, .terms a::before {
  content: "";
  position: absolute; }

/* 
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important; } */

form {
  -moz-placeholder-color: #64849f;
  -ms-input-placeholder-color: #64849f; }
  form ::-webkit-input-placeholder {
    color: #64849f; }
  form ::-moz-placeholder {
    color: #64849f; }

.content {
  max-width: 1200px;
  margin: 0 auto;
  display: block; }

.clearfix:after {
  display: block;
  content: "";
  clear: both; }

body {
  font-family: "GeometriaLight", sans-serif;
  min-width: 320px; }

.large-header {
  position: relative;
  background: -webkit-gradient(linear, left bottom, left top, from(#000c15), to(#00172a));
  background: -webkit-linear-gradient(bottom, #000c15 0%, #00172a 100%);
  background: linear-gradient(0deg, #000c15 0%, #00172a 100%);
  text-align: center;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.wrap {
  position: relative;
  width: 100%;
  min-width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;}
  @media only screen and (max-width:480px){
    .wrap{
      min-width: unset;
      padding: 10px;
    }
  } 
  .wrap .logo {
    width: 100px;    
    z-index: 10;
    position: relative;
    line-height: 0; }
    .wrap .logo a {
      display: inline-block;
      line-height: 0; }
      .wrap .logo a img {
        width: 100%; }

.error_message {
  border: 1px solid #df0024;
  margin-bottom: 25px;
  color: #fff;
  text-align: left;
  padding: 5px 20px;
  font-weight: normal;
  font-size: 14px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  max-width: 300px;
  margin: 0 auto 7px;
  display: none; }

.error_field {
  color: #df0024;
  border-color: #df0024; }

.valid_field {
  border-color: #00be82; }

.form {
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, rgba(0, 18, 33, 0.25)), to(rgba(0, 70, 129, 0.25)));
  background-image: -webkit-linear-gradient(0, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  background-image: linear-gradient(0, rgba(0, 18, 33, 0.25) 0%, rgba(0, 70, 129, 0.25) 100%);
  padding: 30px;
  max-width: 490px;
  width: 100%;


  -webkit-border-radius: 5px;
          border-radius: 5px;
  position: relative;
  z-index: 5000;     margin-top: 15px;
  margin-bottom: 15px; }
  .form::before {
    position: absolute;
    content: '';
    width: 70%;
    height: 2px;
    background: #00be82;
    top: -1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }

h1, h2 {
  font: 18px/1.2 'GeometriaMedium';
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 0 0 15px; }
  h1.register_page, h2.register_page {
    margin-bottom: 20px; }

.welcome_message {
  color: #64849f;
  margin-bottom: 40px; }
  .welcome_message.register_page {
    margin-bottom: 20px; }

input {
  display: block;
  margin: 0 auto 7px;
  padding: 15px 20px; 
  width: 100%;
  height: 100%;
  background: #000f1c;
  border: 1px solid #000f1c;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  outline: none; }

.field-wrap {
  position: relative;
  }
  .field-wrap a {
    color: #395cb7; }
  .field-wrap.field-pass input {
    padding-right: 45px; }

.button {
  font-weight: 100;
  display: inline-block;
  padding: 15px 20px 13px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 1px;
  background: none;
  color: #00be82;
  border: 1px solid #00be82;
  -webkit-border-radius: 25px;
          border-radius: 25px;
  background-color: #001b31;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-appearance: none; }
  .button:hover, .button:focus {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #fb7601;
    border-color: #fb7601; }
    .button:hover .circle, .button:focus .circle {
      border-color: #fb7601;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .button:hover .arrow, .button:hover .arrow:before, .button:hover .arrow:after, .button:focus .arrow, .button:focus .arrow:before, .button:focus .arrow:after {
      background-color: #fb7601;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
  .button:focus {
    background-color: #000f1c; }
  .button.blocked {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    color: #003c66;
    border-color: #003c66;
    pointer-events: none; }
    .button.blocked .circle {
      border-color: #003c66;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .button.blocked .arrow, .button.blocked .arrow:before, .button.blocked .arrow:after {
      background-color: #003c66;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }

.button-block {
  padding: 14px 45px 14px 20px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 15px auto 20px; }

.circle {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid #00be82;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.arrow {
  position: absolute;
  width: 14px;
  height: 1px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #00be82;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  .arrow::before, .arrow::after {
    width: 6px;
    height: 1px;
    bottom: 100%;
    right: -1px;
    background: #00be82;
    -webkit-transform: translate(0%, -100%) rotate(45deg);
        -ms-transform: translate(0%, -100%) rotate(45deg);
            transform: translate(0%, -100%) rotate(45deg);
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .arrow::after {
    bottom: initial;
    top: 100%;
    -webkit-transform: translate(0%, 100%) rotate(-45deg);
        -ms-transform: translate(0%, 100%) rotate(-45deg);
            transform: translate(0%, 100%) rotate(-45deg);
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }

.forgot {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px/1.2 'GeometriaLight'; }
  .forgot a {
    position: relative;
    color: #64849f; }
    .forgot a::before {
      width: 100%;
      height: 1px;
      background: #64849f;
      bottom: -2px; }

p.info {
  color: #64849f;
  font: 14px/1.2 'GeometriaLight';
  margin-bottom: 0;
  margin-top: 15px;
  }
  p.info a {
    position: relative; }
    p.info a::before {
      width: 100%;
      height: 1px;
      background: #64849f;
      bottom: -1px; }

.success {
  display: none; }

.success_icon {
  display: inline-block;
  height: 140px;
  width: 140px;
  position: relative;
  border: 1px solid #00be82;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  margin: 30px auto 50px; }
  .success_icon:after {
    width: 25px;
    height: 47px;
    border-right: 1px solid #00be82;
    border-bottom: 1px solid #00be82;
    -webkit-transform: rotate(45deg) translate(0%, -15%);
        -ms-transform: rotate(45deg) translate(0%, -15%);
            transform: rotate(45deg) translate(0%, -15%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }

.show_password {
  width: 20px;
  height: 17px;
  top: 25px;
  right: 0;
  -webkit-transform: translate(-100%, -50%);
      -ms-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  cursor: pointer; }
  .show_password svg {
    width: 100%;
    height: 100%; }
    .show_password svg path {
      fill: #64849f; }
  .show_password.active svg path {
    fill: #fff; }

.field-terms {
  margin: 20px auto; }
  .field-terms input {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  position: relative;
  background: #000f1c;
  border: 1px solid #000f1c;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  vertical-align: top;
  -webkit-transition: border .2s;
  transition: border .2s; }
  .icon:after {
    width: 6px;
    height: 10px;
    border-right: 1px solid #00be82;
    border-bottom: 1px solid #00be82;
    -webkit-transform: rotate(45deg) translate(0%, 40%);
        -ms-transform: rotate(45deg) translate(0%, 40%);
            transform: rotate(45deg) translate(0%, 40%);
    opacity: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s; }

.check {
  position: relative;
  height: 22px;
  opacity: 0; }
  .check:checked + label .icon {
    border-color: #00be82;
    -webkit-transition: border .2s;
    transition: border .2s; }
    .check:checked + label .icon:after {
      opacity: 1;
      -webkit-transition: opacity .2s;
      transition: opacity .2s; }

.terms {
  position: absolute;
  left: 0;
  top: 0;
  color: #64849f; }
  .terms a {
    color: #64849f;
    position: relative; }
    .terms a::before {
      width: 100%;
      height: 1px;
      background: #64849f;
      bottom: -1px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }

.create {
  min-width: 250px;}

.button.pass {
  margin: 35px 0;
  padding: 15px 40px 13px; }

.info.inner {
  margin-bottom: 10px; }

@media only screen and (max-width: 480px) {
  .form {
    padding: 55px 20px 40px; } }
    .styled-checkbox {
      position: absolute;
      opacity: 0;
    }
    .styled-checkbox + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    .styled-checkbox + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
    }
    .styled-checkbox:hover + label:before {
      background: #6c1a74;
    }
    .styled-checkbox:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    .styled-checkbox:checked + label:before {
      background: #6c1a74;
    }
    .styled-checkbox:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
    .styled-checkbox:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    .styled-checkbox:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
    .terms_conditions_link{
      color: #fff !important;
    opacity: 1;
    text-decoration: underline;
    }