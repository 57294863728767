

.careers-bg {
    background-image: url('./public/landing/img/workplace-1245776_960_720.png');
}


.row{
    display: flex;
}
.col-sm-3{
    width: 25%;
}

.col-sm-6{
    width: 50%;
}

.col-sm-9{
    width: 75%;
}

.values{
    margin-top: 20px;
    margin-bottom: 5px;
}

.subscribe_now.hotelier_in_numbers {
    background-image: url(https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512_960_720.jpg);
}


@media screen and (max-width: 700px) {
    .col-sm-6 {
        width: 100% !important;
    }

    .row{
        display: block !important;
    }
}

@media screen and (max-width: 320px) {
    .sm-sz{
        width: 110% !important;
    }
}
