.cardToken{
    padding: 25px 20px;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    height: 200px;
    position: relative;
}
@media only screen and (max-width:1103px){
    .cardToken{
        height: auto;
    }
}


.btn-htl{
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background: #6c2674;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.ev{
    padding: 10px 5px;
    font-size: 16px;
    border: none;
    background: #60D386;
    color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 1079px) {
    .ev {
        display: block;
        margin-bottom: 18px;
    }
}

.col-row{
    width: 100%;
    display: inline-block;
}
.row-en{
    width: 100%;
    margin-top: 15px;
    text-align: left;
}
.col-en{
    width: 68%;
    display: inline-block;
}
@media only screen and (max-width:1102px){
    .col-en{
        width:100%;
        display: inline-block;
    }
}
.col-en2{
    width: 29%;
    display: inline-block;
}
@media only screen and (max-width:1102px){
    .col-en2{
        width:100%;
        display: inline-block;
    }
}

.kyc{
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background: #ff9300;
    color: white;
    border-radius: 5px;
    margin-left: 10px;
}

.custom-row{
    width: 100%;
    margin-top: 20px;
    background-color: rgb(221, 221, 221);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
}

.custom-col{
    width:32%;
    display: inline-block;
    padding-top:10px;
    padding-bottom:10px;
}

@media only screen and (max-width: 768px) {
    .custom-col {
        width: 100%;
    }
}

.custom-row2{
    width: 100%;
    text-align: center;
    display: flex;
}
@media only screen and (max-width:768px){
    .custom-row2{
        display: block;
    }
}

.custom-col2{
    width:32%;
    display: inline-block;
    padding-top:10px;
    padding-bottom:10px;
    margin-right: 3px;
    margin-left: 3px;
}

@media only screen and (max-width: 768px) {
    .custom-col2 {
        width: 100%;
    }
}
.custom-col4{
    width: 48%;
    margin-right: 10px;
    border: 2px solid #E6EEFA;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    background-color: #E6EEFA;
}
@media only screen and (max-width: 576px) {
    .custom-col4{
        width: 100%;
    }
}
.main-div{
    background: white;
    width: 85%;
    float: right;
    padding: 40px;
    min-height: 100vh;
}

.box {
    margin-top: 20px;
    padding: 14px;
}
.box::before{
    content: '';
    position: absolute;
}
.custom-p{
    color: #000;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
}
.row3{
    width:100%;
    margin-top: 10px;
}
.custom-col3{
    width:48%;
    margin-right: 10px;
    border: 1px solid #E6EEFA;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    background-color: #E6EEFA;
    margin-top: 8px;
}
@media only screen and (max-width: 768px){
    .custom-col3{
        width: 100%;
    }
}
.coin-input{
    display: unset !important;
    margin: unset !important;
    padding: 9px 20px !important;
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    float: right;
    border: none;
     background: unset !important;
     border: unset !important;
     border-radius: unset !important;
    font-size: 14px;
    color: #000 !important;
    letter-spacing: 1px;
    outline: none;
}
@media only screen and (max-width:933px){
    .coin-input{
        width: 100% !important;
    }
}
.image-span{
    position: absolute;
    top: 16px;
    left: 12px;
}

.text-span{
    position: absolute;
    top: 17px;
    left: 32px;
    font-size: 19px;
    font-weight: bold;
}
.text-span2{
    position: absolute;
    top: 17px;
    right: 20px;
    font-size: 16px;
    font-weight: bold;
}
@media only screen and (max-width:480px){
    .text-span2{
        top: 22px;
        right: 5px;
        font-size: 12px;
    }
}
.input-text2{
    position: relative;
    font-size: 19px;
    font-weight: bold;
    top: -25px;
}
@media only screen and (max-width: 576px) {
    .input-text2{
        display: block;
        top: unset;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
}
.btn-edit{
    width: 100%;
    background-color: #60D386;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
}
.btn-edit:hover{
    cursor: pointer;
}
.m-11{
    margin-top:10px;
}
.custom-row5{
    width:100%;
    display: inline-block;
    padding: 15px;
}
.custom-col5{
    width:49%;
    display: inline-block;
    padding-left: 10px;
}
@media only screen and (max-width: 450px) {
    .custom-col5{
        width:100%;
        display: inline-block;
        padding-left: 10px;
        margin-top: 5px;
        float: unset;
        text-align: center;
    }
}
.custom-col6{
    width:49%;
    display: inline-block;
    padding-left: 10px;
}
@media only screen and (max-width: 1025px) {
    .custom-col6{
        width:100%;
        display: inline-block;
        padding-left: 10px;
        margin-top: 5px;
        float: unset;
        text-align: center;
    }
}
.btc-name{
    position: relative;
    top: -7px;
    font-size: 16px;
    font-weight: bold;
}
.btc-value{
    position: relative;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    float: right;
}
@media only screen and (max-width: 450px) {
    .btc-value{
        width:100%;
        display: inline-block;
        padding-left: 10px;
        margin-top: 5px;
        float: unset;
        text-align: center;
    }

}
.active-class::before{
    content: '';
    position: absolute;
    border: 3px solid #6c2674;
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.active-class::after{
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #6c2674;
    top: -3px;
    right: -2px;
    z-index: 3;
}
*{
    outline: none !important;
}
@media only screen and (max-width:470px){
    .box{
        width: 100%;
        padding-left: unset;
        padding-right: unset;
    }
}
.t-l{
    text-align: left !important;
}
.m-t{
    margin-top: 18px;
}
.c-pointer{
    cursor:copy;
}
.changePassword {
    width: 35%;
    margin-left: 30px;
}
.width-60{
    width: 60%;
}
.d-edit{
    display: flex;
}
@media only screen and (max-width:1160px){
    .changePassword {
        width: 100%;
        margin-left: unset;
        display: block !important;
    }
    .width-60{
        width: 100%;
        display: block !important;
        margin-bottom: 15px;
    }
    .d-edit{
        display: block;
    }

}
.input-edit{
    max-width:100% !important;
}



.imgsb{
    width: 20px;
    padding-right: 5px;
    margin-top: 5px;
}
.bg-boc{
    background:white;
    width: 85%;
    float: right;
    padding: 40px;
    paddingTop: 0px;
}
@media only screen and (max-width:470px){
    .bg-boc{
        padding: 10px;
    }
}


.payment-box{
    /*-webkit-box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    /*-moz-box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    /*box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    width: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #6c2474;
}


.payment-box-opp{
    /*-webkit-box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    /*-moz-box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    /*box-shadow: 0px 0px 16px 1px rgba(168,168,168,1);*/
    width: 500px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #6c2474;
}

.halfCircleRight{
    height: 45px;
    width: 20px;
    border-radius: 0 90px 90px 0;
    background:white;
}

.halfCircleLeft{
    height: 45px;
    width: 20px;
    border-radius: 90px 0 0 90px;
    background:white;
}


.hrpymnt{
    border: 1px solid lightgray;
    border-style: dashed;
    width: 350px;
    text-align: center;
    margin: auto;
}


.selectcurr{
    background: #e6eefa;
    border: none;
    height: 60px;
    margin-bottom: 6px;
    font-size: 18px;
    width: 100%;
    color: #64849f;
    font-weight: bolder;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 30px;
}


.drpicon{
    position: absolute;
    width: 15px;
    margin-left: -40px;
    margin-top: 24px;
    color: green;
}
