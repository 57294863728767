.wrap .logo {
  width: 250px !important; }

.login-background-custom {
  background: -webkit-gradient(linear, left top, left bottom, from(#6c1a74), to(#0a032f)) !important;
  background: -webkit-linear-gradient(top, #6c1a74 0%, #0a032f 100%) !important;
  background: linear-gradient(180deg, #6c1a74 0%, #0a032f 100%) !important; }

.main-block-custom-login {
  background-image: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, rgba(17, 21, 37, 0.5)), to(rgba(6, 10, 29, 0.65))) !important;
  background-image: -webkit-linear-gradient(0, rgba(17, 21, 37, 0.5) 0%, rgba(6, 10, 29, 0.65) 100%) !important;
  background-image: linear-gradient(0, rgba(17, 21, 37, 0.5) 0%, rgba(6, 10, 29, 0.65) 100%) !important; }
  .main-block-custom-login::before {
    background: #fff !important; }

.login-title-color-custom {
  color: #fff !important; }

.content-font-color-custom-login {
  color: #d8d8d8 !important; }

.form-placeholder-custom {
  -moz-placeholder-color: #6c1a74 !important;
  -ms-input-placeholder-color: #6c1a74 !important; }
  .form-placeholder-custom ::-webkit-input-placeholder {
    color: #6c1a74 !important; }
  .form-placeholder-custom ::-moz-placeholder {
    color: #6c1a74 !important; }
  .form-placeholder-custom input:-webkit-autofill, .form-placeholder-custom input:-webkit-autofill:hover, .form-placeholder-custom input:-webkit-autofill:focus, .form-placeholder-custom input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #6c1a74 !important;
    color: #6c1a74 !important; }

.input-custom {
  border-color: #6c1a74 !important;
  background-color: #fff !important;
  color: #1d1350 !important; }
  .input-custom:focus {
    border-color: #1d1350 !important; }

.show-password-custom svg path {
  fill: #6c1a74; }

.show-password-custom.active svg path {
  fill: #1d1350; }

.button-custom-l {
  color: #fff !important;
  background-color: #6c1a74 !important;
  border-color: #6c1a74 !important; }
  .button-custom-l:hover {
    color: #fff !important;
    background-color: #1d1350 !important;
    border-color: #1d1350 !important; }
    .button-custom-l:hover .circle-custom-l {
      border-color: #fff !important; }
    .button-custom-l:hover .arrow-custom {
      background: #fff !important; }
      .button-custom-l:hover .arrow-custom:before, .button-custom-l:hover .arrow-custom:after {
        background: #fff !important; }

.circle-custom-l {
  border-color: #fff !important; }

.arrow-custom-l {
  background: #fff !important; }
  .arrow-custom-l::before {
    background: #fff !important; }
  .arrow-custom-l::after {
    background: #fff !important; }


.button-custom.blocked {
  color: #6c1a74 !important;
  border-color: #6c1a74 !important;
  background: rgba(50, 51, 51, 0.45) !important; }

.button-custom.blocked .circle-custom {
  border-color: #1d1350 !important; }

.button-custom.blocked .arrow-custom {
  background: #1d1350 !important; }
  .button-custom.blocked .arrow-custom::before {
    background: #1d1350 !important; }
  .button-custom.blocked .arrow-custom::after {
    background: #1d1350 !important; }

.underline-custom:before {
  background: #d8d8d8 !important; }

.error-message-custom {
  border-color: #df0024 !important;
  color: #fff !important; }

.check-icon-custom {
  background: #fff !important;
  border-color: #6c1a74 !important; }
  .check-icon-custom:after {
    border-color: #6c1a74 !important; }

.success-icon-custom {
  border-color: #6c1a74 !important; }
  .success-icon-custom:after {
    border-color: #6c1a74 !important; }
